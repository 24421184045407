import IUser from "../security/IUser";
import IDateTime from "../api/IDateTime";
import IMessage from "./IMessage";

export const STATE_NEW = 0;
export const STATE_VISITED = 1;
export const STATE_DELETED = 2;
export const STATE_LOADING = 3;

export enum STATES {
    STATE_NEW,
    STATE_VISITED,
    STATE_DELETED,
    STATE_LOADING
}

export default interface INotification {
    id: number,
    // uuid: string,
    text: string,
    state: STATES,
    recipient: IUser,
    visitedAt: string,
    scheduledAt: IDateTime,
    message: IMessage
    sender?: IUser
}