import React from "react";
import {Badge, Col, Dropdown, Menu, Row, Tag, Tooltip} from "antd";
import {connect, RootStateOrAny} from 'react-redux'
import {
    LogoutOutlined,
    QuestionCircleOutlined,
    SettingOutlined,
    TeamOutlined,
    UserSwitchOutlined
} from '@ant-design/icons';
import Icon from 'old/util-components/Icon';
import {disableMasquerade, signOut} from 'redux/actions/Auth';
import IUser from "../../model/interface/security/IUser";
import IntlMessage from "../../old/util-components/IntlMessage";
import {Link} from "react-router-dom";
import UserAvatar from "../app/security/UserAvatar";
import IEmployee from "../../model/interface/company/IEmployee";
import EmployeeLabel from "../app/company/employees/EmployeeLabel";
import ScrollContainer from "../shared/scrollContainer/ScrollContainer";
import NavTheme from "./NavTheme";

interface IProps {
    user: IUser,
    masqueradeActive: boolean
    signOut: () => void
    disableMasquerade: () => void
    delegatedBy: IEmployee[]
}

interface IState {
}

class NavProfile extends React.Component<IProps, IState> {
    render() {
        const {user, masqueradeActive, delegatedBy} = this.props
        return (
            <Dropdown placement="bottomRight" overlay={(
                <div className="nav-profile nav-dropdown" style={{minWidth: 280}}>
                    <div className={"nav-profile-header"}>
                        <Row align={"middle"} className={'flex-column'}>
                            <UserAvatar size={45} user={user}/>
                            {user.employees[0] && user.employees[0].fullName}
                            <div style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: "calc(100% - 55px)"}}>
                                <div className={"text-muted"}>{user.username}</div>
                            </div>
                        </Row>
                        <ScrollContainer orientation={'horizontal'}>
                            {masqueradeActive && (

                                <Tag className={"mt-2"} color={"red"}>Přihlášení za jiného uživatele aktivní</Tag>
                            )}
                            {delegatedBy.length > 0 && (
                                <Tag className={"mt-2"} color={"yellow"}>
                                    <Row>
                                        <Col>
                                            Zástup za:
                                        </Col>
                                        <Col className={'pb-2'}>
                                            {delegatedBy.map(emp => (
                                                <EmployeeLabel employee={emp}/>
                                            ))}
                                        </Col>
                                    </Row>
                                </Tag>
                            )}
                        </ScrollContainer>
                    </div>
                    <div className="nav-profile-body">
                        <Menu>
                            {user.employees!.map((employee, i) => {
                                return (
                                    <Menu.Item key={'employee-' + i}>
                                        <Link to={'/app/company/employees/' + employee.id}>
                                            <Icon className="mr-3" type={TeamOutlined}/>
                                            <span className="font-weight-normal">
                                                {user.employees!.length > 1 ? employee.fullName : 'Karta zaměstnance'}
                                            </span>
                                        </Link>
                                    </Menu.Item>
                                );
                            })}
                            <Menu.Item key={"edit"}>
                                <Link to={"/app/security/users/me"}>
                                    <Icon className="mr-3" type={SettingOutlined}/>
                                    <span className="font-weight-normal">{"Nastavení"}</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <NavTheme className={'font-weight-normal'}/>
                            </Menu.Item>
                            <Menu.Item key={"help"}>
                                <Link to={"/app/help"}>
                                    <Icon className="mr-3" type={QuestionCircleOutlined}/>
                                    <span className="font-weight-normal">{"Nápověda"}</span>
                                </Link>
                            </Menu.Item>

                            {/*{menuItem.map((el, i) => {*/}
                            {/*    return (*/}
                            {/*        <Menu.Item key={i}>*/}
                            {/*            <Link to={el.path}>*/}
                            {/*                <a>*/}
                            {/*                    <Icon className="mr-3" type={el.icon}/>*/}
                            {/*                    <span className="font-weight-normal">{el.title}</span>*/}
                            {/*                </a>*/}
                            {/*            </Link>*/}
                            {/*        </Menu.Item>*/}
                            {/*    );*/}
                            {/*})}*/}
                            {masqueradeActive && (
                                <Menu.Item key={"disableMasquerade"} onClick={() => this.props.disableMasquerade()}>
                                    <span>
                                      <UserSwitchOutlined className="mr-3"/>
                                      <span className="font-weight-normal">
                                          Zpět na originálního uživatele
                                      </span>
                                    </span>
                                </Menu.Item>
                            )}
                            <Menu.Item key={"signout"} onClick={() => this.props.signOut()}>
                                <span>
                                  <LogoutOutlined className="mr-3"/>
                                  <span className="font-weight-normal">
                                      <IntlMessage id={"user.signOut"}/>
                                  </span>
                                </span>
                            </Menu.Item>
                        </Menu>
                    </div>
                </div>
            )} trigger={["click"]}>
                <div>
                    {masqueradeActive || delegatedBy.length ? (
                        <Tooltip placement={'bottom'} title={<div>
                            {masqueradeActive && <div>Přihlášení za jiného uživatelé je aktivní!</div>}
                            {delegatedBy.length && <div>Zástup je aktivní!</div>}
                        </div>}>
                            <Badge style={{backgroundColor: masqueradeActive ? '#ff6b72' : '#fadb14'}} count={"!"}>
                                <UserAvatar size={36} user={user}/>
                            </Badge>
                        </Tooltip>
                    ) : (
                        <UserAvatar size={36} user={user} className={'nav-icon'}/>
                    )}
                </div>
            </Dropdown>
        );
    }
}


const mapStateToProps = ({setup}: RootStateOrAny) => {
    const {user, masqueradeActive, delegatedBy} = setup
    return {user, masqueradeActive, delegatedBy}
}

export default connect(mapStateToProps, {signOut, disableMasquerade})(NavProfile)
