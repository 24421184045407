import {IAppState} from "../store";
import ITranslation from "../../model/interface/ui/dictionary/ITranslation";

export const getMessage = (store: IAppState, path: string, params: {
    [key: string]: string | number
} = {}): string => {
    const term = store.setup.terms.find(t => t.path === path)
    const language = store.setup.languages[0] //TODO load language from state/user, once select is implemented
    if (term) {
        let translation = term.translations.find(t => t.language === language.uuid)
        if (term.children.length > 0) {
            let randomTranslations: ITranslation[] = []
            term.children.forEach(c => {
                const child = store.setup.terms.find(t => t.uuid === c)
                if (child && child.code === null) {
                    let childTranslation = child.translations.find(t => t.language === language.uuid)
                    childTranslation && randomTranslations.push(childTranslation)
                }
            })
            if (randomTranslations.length > 0) {
                translation = randomTranslations[Math.floor(Math.random() * (randomTranslations.length))]
            }
        }
        if (translation) {
            let text = translation.text
            Object.entries(params).forEach(([key, value]) => {
                text?.replace(key, value.toString())
            })
            return text || path
        }
    }
    return path
}

export default {
    getMessage
}