import React, {RefObject} from "react";
import {Card, Form, FormInstance, Input, Select, Switch} from "antd";
import IField, {
    COMPOSITE_FIELD_TYPE,
    FIELD_MODE_COMPOSITE,
    FIELD_MODE_RELATION,
    FIELD_MODE_SCALAR,
    FIELD_NUMBER_TYPES,
    FIELD_TYPE,
    RELATION_FIELD_TYPE
} from "../../../../../../model/interface/dataStorage/IField";
import FormFieldType from "../formField/FormFieldType";
import FormFieldSelectOptionEditor from "../formField/editor/FormFieldSelectOptionEditor";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import FormElementField from "../FormElementField";
import FormFieldAutocompleteEditor from "../formField/editor/FormFieldAutocompleteEditor";
import FormFieldWYSIWYGEditor from "../formField/editor/FormFieldWYSIWYGEditor";
import FormFieldFilePickerEditor from "../formField/editor/FormFieldFilePickerEditor";
import FormFieldContentTypeEditor from "../formField/editor/FormFieldContentTypeEditor";
import ConditionEditor, {ICondition} from "./ConditionEditor";
import IFormStructureNode from "../../../../../../model/interface/form/IFormStructureNode";
import Title from "antd/es/typography/Title";
import IContentType from "../../../../../../model/interface/dataStorage/IContentType";
import FormFieldRateEditor from "../formField/editor/FormFieldRateEditor";
import FormFieldContentTypeCascadeEditor from "../formField/editor/FormFieldContentTypeCascadeEditor";
import FormFieldCompanyStructureEditor from "../formField/editor/FormFieldCompanyStructureEditor";
import FormFieldEmployeeEditor from "../formField/editor/FormFieldEmployeeEditor";
import IBaseProps from "../../../../../../model/interface/IBaseProps";
import EmployeesService from "../../../../../../model/service/company/EmployeesService";
import WorkflowStatesService from "../../../../../../model/service/file/WorkflowStatesService";
import FilesService from "../../../../../../model/service/file/FilesService";
import FieldCollapseInfo from "../../../content-type/field/FieldCollapseInfo";
import CompositeFieldApprovalOptionsEditor
    from "../../../content-type/field/optionEditors/composite/CompositeFieldApprovalOptionsEditor";
import FieldPhoneNumberOptionsEditor from "../../../content-type/field/optionEditors/FieldPhoneNumberOptionsEditor";
import ActionPicker from "../../../../../shared/pickers/ActionPicker";
import FieldCodeOptionsEditor from "../../../content-type/field/optionEditors/FieldCodeOptionsEditor";
import FormFieldDateEditor from "../formField/editor/FormFieldDateEditor";

interface IProps extends IBaseProps {
    options: IFieldOptions
    field: IField
    fields: IFormStructureNode[]
    contentType: IContentType,
    formRef: RefObject<FormInstance>
}

class FieldEditor extends React.Component<IProps> {

    static detectType(field: IField) {
        switch (field.mode) {
            case FIELD_MODE_SCALAR:
                const type = field.type.toLowerCase();
                if (FIELD_NUMBER_TYPES.includes(field.type)) {
                    return FormFieldType.FIELD_NUMBER
                } else if (type.includes('icon')) {
                    return FormFieldType.FIELD_ICON
                } else if (type.includes('string')) {
                    return FormFieldType.FIELD_TEXT
                } else if (type.includes('text')) {
                    return FormFieldType.FIELD_WYSIWYG
                } else if (type.includes('date')) {
                    return FormFieldType.FIELD_DATE
                } else if (type.includes('bool')) {
                    return FormFieldType.FIELD_BOOLEAN
                } else if (field.type === FIELD_TYPE.PHONE_NUMBER) {
                    return FormFieldType.FIELD_PHONE_NUMBER
                } else if (field.type === FIELD_TYPE.BANK_ACCOUNT_NUMBER) {
                    return FormFieldType.FIELD_BANK_ACCOUNT_NUMBER
                } else if (field.type === FIELD_TYPE.ICO) {
                    return FormFieldType.FIELD_ICO
                } else if (type.includes('count')) {
                    return FormFieldType.FIELD_COUNT
                } else if (type.includes('rate')) {
                    return FormFieldType.FIELD_RATE
                } else if (type.includes('currency')) {
                    return FormFieldType.FIELD_CURRENCY
                } else if (type.includes('url')) {
                    return FormFieldType.FIELD_URL
                } else if (type.includes('email')) {
                    return FormFieldType.FIELD_EMAIL
                } else if (type.includes('base64')) {
                    return FormFieldType.FIELD_SIGNATURE
                } else if (type.includes('color')) {
                    return FormFieldType.FIELD_COLOR
                } else if (field.type === FIELD_TYPE.CODE) {
                    return FormFieldType.FIELD_CODE
                }
                return FormFieldType.FIELD_TEXT
            case FIELD_MODE_RELATION:
                const target = field.targetEntity;
                if (target) {
                    if (target.includes(FilesService.getRecordClassName())) {
                        return FormFieldType.FIELD_FILE
                    } else if (target.includes(EmployeesService.getInstance().getRecordClassName())) {
                        return FormFieldType.FIELD_EMPLOYEE
                    } else if (target.includes('Company')) {
                        return FormFieldType.FIELD_COMPANY_STRUCTURE
                    } else if (target.includes(WorkflowStatesService.getRecordClassName())) {
                        return FormFieldType.FIELD_WORKFLOW_STATE
                    }
                }
                return FormFieldType.FIELD_CONTENT_TYPE
            case FIELD_MODE_COMPOSITE:
                if (field.type === COMPOSITE_FIELD_TYPE.APPROVAL) {
                    return FormFieldType.COMPOSITE_FIELD_APPROVAL
                }
                return ''
            default:
                return ''
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        const {options, formRef} = this.props
        const forceCleanList = [
            'type', 'datePicker', 'wysiwygPackage', 'employeeOnlyDirectChildren', 'employeeStructureRoot'
        ]
        forceCleanList.forEach(item => {
            if (options[item] !== prevProps.options[item]) {
                if (options.initialValue) {
                    formRef.current?.setFieldsValue({'initialValue': undefined})
                }
            }
        })
    }

    onValuesChange = (values: any) => {
        this.setState({...values})
    }

    onFieldSettingsChange = (options: IFieldOptions, afterCallback?: () => void) => {
        this.setState({...options}, afterCallback)
    }

    addCondition = (disabledConditions: ICondition[]) => {
        this.setState({disabledConditions})
    }

    render() {
        const {field, fields, contentType, match, history, formRef, options} = this.props
        const {type, selectOptions, required, disabledConditions, multiple} = options

        return (
            <div>
                <FieldCollapseInfo field={field}/>
                <Form.Item name={'label'} label={'Zvolte nazev'} rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'required'} label={'Pole je povinne'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                {required && (
                    <Form.Item name={'requiredText'} label={'Hlaska pro povinne pole'} rules={[{required: true}]}
                               initialValue={'Tato položka je povinná'}>
                        <Input/>
                    </Form.Item>
                )}
                <Form.Item name={'disabled'} label={'Zakázat'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'placeholder'} label={'Nápověda'}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'showClear'} label={'Možnost smazat hodnotu'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'noStyle'} label={'Bez stylu'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'noLabel'} label={'Bez titulku'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={'type'} label={'Zvolte typ vstupu'} rules={[{required: true}]}>
                    <Select>
                        {FormFieldType.FIELD_TYPES.sort((a, b) => a.label > b.label ? 1 : -1).map(type => (
                            <Select.Option key={type.value} value={type.value}>{type.label}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name={'editRightsAction'} label={'Zakázat na základě oprávnění k akci'}>
                    <ActionPicker contentTypeUuid={contentType.uuid}/>
                </Form.Item>
                <Form.Item name={'readRightsAction'} label={'Skrýt na základě oprávnění k akci'}>
                    <ActionPicker contentTypeUuid={contentType.uuid}/>
                </Form.Item>
                {type === FormFieldType.FIELD_SLIDER && (
                    <Card>
                        <Form.Item label={"Minimalna hodnota"} name={"sliderMin"} rules={[{required: true}]}
                                   initialValue={1}>
                            <Input type={"number"}/>
                        </Form.Item>
                        <Form.Item label={"Maximalna hodnota"} name={"sliderMax"} rules={[{required: true}]}
                                   initialValue={100}>
                            <Input type={"number"}/>
                        </Form.Item>
                        <Form.Item label={"Viditelna hodnota"} name={"sliderTooltipVisible"}
                                   valuePropName={'checked'}>
                            <Switch/>
                        </Form.Item>
                    </Card>
                )}
                {[FormFieldType.FIELD_DATE, FormFieldType.FIELD_DATE_RANGE].includes(type) && (
                    <Card>
                        <FormFieldDateEditor options={options}/>
                    </Card>
                )}
                {[FormFieldType.FIELD_PHONE_NUMBER].includes(type) && (
                    <Card>
                        <FieldPhoneNumberOptionsEditor buildFieldName={(...args) => args} options={options}/>
                    </Card>
                )}
                {type === FormFieldType.FIELD_AUTOCOMPLETE && (
                    <FormFieldAutocompleteEditor
                        onChange={this.onFieldSettingsChange}
                        options={options}
                        formRef={formRef}
                    />
                )}
                {type === FormFieldType.FIELD_CONTENT_TYPE && (
                    <FormFieldContentTypeEditor
                        onChange={this.onFieldSettingsChange}
                        options={options}
                        history={history}
                        match={match}
                        formRef={formRef}
                        field={field}
                    />
                )}
                {type === FormFieldType.FIELD_CONTENT_TYPE_CASCADE && (
                    <FormFieldContentTypeCascadeEditor
                        onChange={this.onFieldSettingsChange}
                        options={options}
                        formRef={formRef}
                        field={field}
                    />
                )}
                {type === FormFieldType.FIELD_WYSIWYG && (
                    <FormFieldWYSIWYGEditor options={options}/>
                )}
                {type === FormFieldType.FIELD_FILE && (
                    <FormFieldFilePickerEditor field={field}/>
                )}
                {type === FormFieldType.FIELD_RATE && (
                    <FormFieldRateEditor options={options}/>
                )}
                {type === FormFieldType.FIELD_EMPLOYEE && (
                    <FormFieldEmployeeEditor
                        options={options}
                        formRef={formRef}
                        contentType={this.props.contentType}
                        history={history}
                        match={match}
                        field={field}
                    />
                )}
                {type === FormFieldType.FIELD_COMPANY_STRUCTURE && (
                    <FormFieldCompanyStructureEditor
                        onChange={this.onFieldSettingsChange}
                        options={{
                            ...options,
                            companyStructureMultiple: field.type === RELATION_FIELD_TYPE.MANY_TO_MANY,
                            companyStructureAccepts: "employee"
                        }}
                        formRef={formRef}
                        history={history}
                        match={match}
                        field={field}
                    />
                )}
                {type === FormFieldType.COMPOSITE_FIELD_APPROVAL && (
                    <Card>
                        <CompositeFieldApprovalOptionsEditor buildFieldName={(...args) => args} options={options}
                                                             contentType={contentType}/>
                    </Card>
                )}
                {type === FormFieldType.FIELD_CODE && (
                    <Card>
                        <FieldCodeOptionsEditor buildFieldName={(...args) => args} options={options}/>
                    </Card>
                )}
                {type && (
                    <FormElementField
                        field={field}
                        functions={{
                            ...{
                                getNode: () => ({field}),
                                getContentType: () => contentType,
                            } as any
                        }}
                        type={'field'}
                        label={'Zvolte pocatecni hodnotu'}
                        id={'field-default-value'}
                        options={{...options, disabled: false, showClear: true, required: false}}
                        preview={true}
                        customFieldName={'initialValue'}
                    />
                )}

                {type === FormFieldType.FIELD_SELECT && (
                    <FormFieldSelectOptionEditor
                        options={selectOptions}
                        multiple={multiple || false}
                    />
                )}
                <div>
                    <Title level={4}>Deaktivovat pole na základě podmínek</Title>
                    <ConditionEditor value={disabledConditions || []} onChange={this.addCondition}
                                     fields={contentType.fields} fieldNodes={fields}/>
                </div>
            </div>
        )
    }
}

export default FieldEditor