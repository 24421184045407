import {Checkbox, Col, Radio, Row, Select, Spin} from 'antd';
import React from "react";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import _, {debounce} from "underscore";
import IRestServiceOptions from "../../../../../../model/interface/api/IRestServiceOptions";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../../redux/selectors";
import IRepositoryService from "../../../../../../model/interface/IRepositoryService";
import PresenterBuilder from "../../../../../../views/dataStorage/PresenterBuilder";
import IRestResource from "../../../../../../model/interface/api/IRestResource";
import IFormElementProps from "../../../../../../model/interface/form/IFormElementProps";
import IRestServiceOrders from "../../../../../../model/interface/api/IRestServiceOrders";
import Utils from "../../../../../../utils";
import IField from "../../../../../../model/interface/dataStorage/IField";
import ReactDOMServer from 'react-dom/server'
import ActionButton from "../../../../action/ActionButton";
import {IActionResult} from "../../../../../../model/service/dataStorage/ActionsService";
import store from "../../../../../../redux/store";
import ProSelect from "../../../../../shared/input/ProSelect";

const {Option} = Select;

interface IProps extends IFormElementProps {
    value?: number,
    onChange?: (key?: number | string | (string | number)[], label?: string | JSX.Element | (string | JSX.Element)[]) => void
    options: IFieldOptions
    findServiceByClassName: (name: string) => IRepositoryService
    field: IField
}

interface IState {
    value?: number | string | (string | number)[],
    data: { [x: string]: string | JSX.Element },
    loading: boolean
    options: IFieldOptions
}

const SELECT_ALL = '_all'

class FormFieldContentType extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            data: {},
            loading: false,
            options: props.options
        };
        this.fetch = debounce(this.fetch, 800);
    }

    isUnmounted = false;
    inputRef: any

    componentDidMount() {

        try {
            this.fetchData().then(() => this.selectActive()).then()
        } catch (e) {
            console.error(e)
        }
    }

    autoFocus() {
        const {autoFocus} = this.props.options
        autoFocus && setTimeout(() => this.inputRef?.focus?.(), 100)
    }

    componentWillUnmount() {
        this.isUnmounted = true;
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.value !== prevProps.value) {
            this.selectActive()
        }
        this.autoFocus()
    }

    getService() {
        const {options, findServiceByClassName} = this.props

        if (this.props.field && this.props.field.targetEntity) {
            return findServiceByClassName(this.props.field.targetEntity)
        }

        // @deprecated
        return options.contentTypeFullClassName ? findServiceByClassName(options.contentTypeFullClassName) : undefined;
    }


    selectActive() {
        if (this.props.value && !this.isUnmounted) {
            return this.setState({
                value: Utils.parseObjectToIdentifier(this.props.value, 'uuid'),
                loading: false
            });
        }
        this.setState({value: undefined})
    }

    fetch = (value: string) => {
        const {options} = this.props
        console.log('fetch', options.contentTypeAutocompleteMin, value.length >= (options.contentTypeAutocompleteMin!), options.contentTypeAutocompleteField, options.contentTypeAutocompleteMode);

        if (options.contentTypeAutocompleteMin
            && value.length >= (options.contentTypeAutocompleteMin)
            && options.contentTypeAutocompleteField
            && options.contentTypeAutocompleteMode) {
            const params: IRestServiceOptions = {
                filters:
                    {
                        '0': {
                            value: value,
                            field: options.contentTypeAutocompleteField,
                            type: options.contentTypeAutocompleteMode
                        }
                    }
            }
            this.fetchData(params).then();
        }
    }

    buildOrderCriteria() {
        let orderBy = {} as IRestServiceOrders
        const {options} = this.props
        if (options.contentTypeOrderField) {
            const field = _.findWhere(this.getService()?.getFields() || [], {uuid: options.contentTypeOrderField})
            if (field) {
                orderBy[field.name] = {
                    field: field.name
                }
            }
        }
        return orderBy
    }

    fetchData(params?: IRestServiceOptions) {
        const service = this.getService()
        const {options} = this.props
        const {contentTypePresenter, contentTypeFilters} = options
        if (!service || !service.choiceList) {
            console.error('Service is not defined', params)
            return new Promise(() => ([]))
            // throw new Error('Service is not defined')
        }
        const filters = {...params?.filters, ...contentTypeFilters}
        const route = selectors.routes.extractRouteParametersFromUrl(store.getState(), window.location.pathname)
        this.setState({data: {}, loading: true})
        return service.choiceList(contentTypePresenter || service.getDefaultPresenter().name, {
            ...params,
            filters: filters,
            order: this.buildOrderCriteria(),
            route
        }).then(({results}) => {
            this.setState({
                data: results,
                loading: false
            })
        })
    }

    formatData() {
        let {data} = this.state
        if (!data || typeof data !== 'object') {
            return []
        }
        let choices = Object.keys(data).map(id => ({
            key: id,
            label: data[id],
            value: id,
        }))
        if (this.props.options.processData) {
            choices = this.props.options.processData(choices)
        }
        //TODO label might be null , fix here or in backend ???
        choices.sort((a: any, b: any) => {
            return (typeof a.label === 'object' ? a.label?.props?.children + "" : a.label + "")
                .localeCompare(typeof b.label === 'object' ? b.label?.props!.children + "" : b.label + "")
        })
        return choices
    }

    getLabel(resource: IRestResource) {
        const {options} = this.props
        const service = this.getService()
        const presenter = options.contentTypePresenter ?
            service?.getPresenter(options.contentTypePresenter) : service?.getDefaultPresenter()
        return presenter ? PresenterBuilder.build(presenter, resource, presenter.options || {}) : ''
    }

    handleChange = (option: any) => {
        const {multiple} = this.props.options
        let value: any
        if (multiple) {
            if (!Array.isArray(option)) {
                option = [option]
            }
            value = option ? option.map((oneOption: any) => typeof oneOption === "object" ? oneOption.value : oneOption) : []
            if (value.find((v: string | number) => v === SELECT_ALL)) {
                value = this.formatData().map(d => d.key)
            }
        } else {
            value = (typeof option === "object" && option) ? option.value : option
        }
        this.setState(() => ({
            value,
            loading: false,
        }), this.updateParent)
    };

    handleCheckboxChange(option: any) {

        const id = (typeof option === "object" && option) ? option.value : option
        let currentValue = this.state.value;
        if (typeof currentValue !== 'object') {
            currentValue = [];
        }
        const index = currentValue.indexOf(id)
        if (index >= 0) {
            currentValue.splice(index, 1)
        } else {
            currentValue.push(id)
        }
        this.handleChange(currentValue)
    }

    updateParent = () => {
        const {value, data} = this.state
        this.props.onChange?.(value, Array.isArray(value) ? value.map(v => data[v]) : (value ? data[value] : ''))
    }

    onCreate = (result?: IActionResult) => {
        return new Promise<void>(resolve => {
            const resource = result?.resources[0]
            if (resource) {
                const {value} = this.state
                const {multiple} = this.props.options
                this.setState((state) => {
                    return resource.uuid ? {
                        data: {
                            ...state.data,
                            [resource.uuid]: this.getLabel(resource)
                        }
                    } : {...state}
                }, () => {
                    this.handleChange(multiple && Array.isArray(value) ?
                        [...value, {value: resource.uuid}] : {value: resource.uuid})
                    resolve()
                })
            }
        })
    }

    render() {
        const {loading, value} = this.state
        const {
            style,
            placeholder,
            showClear,
            contentTypeMode,
            disabled,
            multiple,
            contentTypeAllowCreation,
            contentTypeCreationButtonType,
            contentTypeCreationButtonIcon,
            contentTypeCreationButtonText,
            contentTypeCreationAction,
            allowSelectAll
        } = this.props.options
        const props = {style, placeholder, allowClear: showClear}
        const data = this.formatData()
        let widgetMode = contentTypeMode

        if (!widgetMode) {
            widgetMode = 'auto'
        }
        if (widgetMode === "auto") {
            widgetMode = data.length < 6 ? 'radio' : data.length < 30 ? 'select' : 'autocomplete'
        }
        const mode = multiple ? 'multiple' : undefined

        const service = this.getService()
        const contentType = service && service.getContentType ? service.getContentType() : null
        const creationAction = contentType && contentType.actions.find(a => a.uuid === contentTypeCreationAction)

        try {
            return <Row><Col className={'flex-grow-1'}>{{
                radio: (
                    <Spin spinning={loading} size={"small"}>
                        {mode === 'multiple' ? (
                            <Checkbox.Group value={Array.isArray(value) ? value : []}>
                                {data.map(item => (
                                    <Checkbox
                                        onChange={() => this.handleCheckboxChange(item.value)}
                                        key={item.value} className={'d-inline-flex align-items-center'}
                                        value={item.value}>
                                        {item.label}
                                    </Checkbox>
                                ))}
                            </Checkbox.Group>
                        ) : (
                            <Radio.Group
                                disabled={disabled}
                                value={value}
                                onChange={(e) => this.handleChange(e.target.value)}
                            >
                                {data.map(value => (
                                    <Radio key={value.value} className={'d-inline-flex align-items-center'}
                                           value={value.value}>{value.label}</Radio>
                                ))}
                            </Radio.Group>
                        )}
                    </Spin>
                ),
                auto: 'Unable to determine mode!',
                select: (
                    <ProSelect
                        {...props}
                        inputRef={ref => this.inputRef = ref}
                        showSearch
                        mode={mode}
                        value={value}
                        disabled={disabled}
                        allowClear={showClear}
                        loading={loading}
                        allowSelectAll={allowSelectAll}
                        notFoundContent={loading ? <Spin size="small"/> : null}
                        filterOption={(input, option) =>
                            Utils.stringContains(ReactDOMServer.renderToString(option?.label as any), input)
                        }
                        options={data}
                        onChange={(value: any) => this.handleChange(value)}
                        onDropdownVisibleChange={open => open && data.length === 0 && this.fetchData()}
                        className={'w-100'}
                    />
                ),
                autocomplete: (
                    <Select
                        {...props}
                        disabled={disabled}
                        className={'w-100'}
                        showSearch
                        mode={mode}
                        value={value}
                        allowClear={showClear}
                        notFoundContent={loading ? <Spin size="small"/> : null}
                        loading={loading}
                        //onSearch={this.fetch}
                        onChange={this.handleChange}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            Utils.stringContains(ReactDOMServer.renderToString(option?.children), input)
                        }
                    >
                        {data.map(d => (
                            <Option key={d.value} value={d.value}>{d.label}</Option>
                        ))}
                    </Select>
                ),
                default: (
                    <>Invalid mode: {widgetMode}</>
                )
            }[widgetMode]}
            </Col>
                {contentTypeAllowCreation && creationAction && (
                    <ActionButton onFinish={this.onCreate} options={{
                        label: contentTypeCreationButtonText || '',
                        icon: contentTypeCreationButtonIcon,
                        type: contentTypeCreationButtonType,
                        size: 'middle',
                        actionId: creationAction.uuid
                    }} action={creationAction}/>
                )}
            </Row>
        } catch (e) {
            return <>Render failed: {JSON.stringify(e)}</>
        }
    }
}

const mapStateToProps = (store: RootStateOrAny) => {
    return {
        findServiceByClassName: (name: string) => selectors.services.findOneByFullClassName(store, name)
    }
}
export default connect(mapStateToProps)(FormFieldContentType)