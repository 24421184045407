import React from "react"
import {Col, Row, Tag, Timeline, Typography} from "antd";
import moment, {Moment} from "moment";
import {ICalendarSharedProps, IEvent} from "./Calendar";
import {MomentBuilder} from "../../../utils/MomentBuilder";


interface IState {
    selectStart?: Moment
    selectEnd?: Moment
    newEvent?: IEvent
}

interface IProps extends ICalendarSharedProps {
    mode: 'single' | 'week'
}

const HOUR_HEIGHT = 40

class Days extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    onSelect = (start: Moment, end: Moment) => {
        const {onSelect} = this.props;
        onSelect?.(start, end)
    }

    getDays() {
        const {date, mode} = this.props;
        if (mode === 'single') {
            return [date]
        }

        return Array.from(Array({single: 1, week: 7}[mode]).keys()).map(day => date.clone().day(day))
    }

    getSelectedHour(e: React.MouseEvent<HTMLElement>) {
        const rect = e.currentTarget.getBoundingClientRect()
        const hours = (e.clientY - rect.top) / HOUR_HEIGHT
        const wholeHours = Math.floor(hours)
        return wholeHours + ((Math.ceil(((hours - wholeHours) * 60) / 15) * 15) / 60)
    }

    selectByDrag = (e: React.MouseEvent<HTMLElement>, date: Moment, done: boolean = false) => {
        date = date.clone().hour(this.getSelectedHour(e))
        this.props.onSelect && this.setState(state => {
            if (state.selectStart && state.newEvent) {
                const [start, end] = [moment.min(state.selectStart, date), moment.max(state.selectStart, date)]
                if (done) {
                    this.onSelect(start, end.isSame(start) ? end.add(15, 'minute') : end)
                }
                return {
                    selectEnd: date,
                    newEvent: {
                        ...state.newEvent,
                        startAt: start,
                        endAt: end
                    }
                }
            }
            document.onkeydown = (evt) => {
                if ("key" in evt && (evt.key === "Escape" || evt.key === "Esc")) {
                    this.unsetDragSelect()
                    document.onkeydown = null
                }
            }
            return {
                selectStart: date,
                newEvent: {
                    startAt: date, color: '#17bcff', label: '(bez názvu)', key: '',
                    endAt: date.clone().add(15, 'minute')
                }
            }
        })
    }

    buildColumnClass(day: Moment) {
        const {highlightWeekend} = this.props
        let columnClass = ''
        const freeDay = this.getFreeDay(day)
        if ((highlightWeekend && [6, 7].includes(day.isoWeekday())) || freeDay) {
            columnClass += 'bg-gray-lightest'
        }
        return columnClass;
    }

    getFreeDay(day: Moment) {
        const {highlightHolidays, freeDays} = this.props
        return highlightHolidays ? freeDays?.find(f => MomentBuilder.build(f.date).isSame(day, 'date')) : undefined
    }

    unsetDragSelect = () => {
        this.setState({newEvent: undefined, selectEnd: undefined, selectStart: undefined})
    }

    render() {
        const {events, eventRender} = this.props
        const {newEvent} = this.state
        const days = this.getDays()

        const allEvents = newEvent ? [...(events || []), newEvent] : events

        return (
            <div className={'w-100'} onMouseLeave={this.unsetDragSelect}>
                <Row className={'w-100 prevent-select'}>
                    <Col span={3}></Col>
                    {days.map(day => {
                        return (
                            <Col onClick={() => this.onSelect(day.clone().startOf('d'), day.clone().endOf('d'))}
                                 span={Math.floor(21 / days.length)}
                                 className={'text-center cursor-pointer pt-2 ' + this.buildColumnClass(day)}>
                                <Typography.Text>{day.format('dddd')}</Typography.Text>
                                <Typography.Title className={'mt-2 '} level={3}>
                                <span className={'p-1 rounded '
                                    + (moment().isSame(day, 'D') ? 'bg-info text-white' : '')}>
                                    {day.format('D')}
                                </span>
                                </Typography.Title>
                                <Typography.Text className={'font-size-sm'} type={"secondary"}>
                                    {this.getFreeDay(day)?.type?.description}
                                </Typography.Text>
                            </Col>
                        )
                    })}
                </Row>
                <Row className={'w-100'}>
                    <Col span={3} className={'prevent-select'}>
                        <Timeline mode={'right'} className={'pr-3'}>
                            {Array.from(Array(25).keys()).map((hour, i, array) => (
                                <Timeline.Item className={i === array.length - 1 ? 'p-0' : ''}>
                                    {i < array.length - 1 && (
                                        <div style={{height: HOUR_HEIGHT - 20 + 'px'}}>
                                            {hour.toString().padStart(2, '0')}
                                        </div>
                                    )}
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    </Col>
                    {days.map(day => {
                        const todayEvents = allEvents?.sort((a, b) => a.startAt.valueOf() - (b.startAt?.valueOf() || 0))
                            .filter(e => day.isBetween(e.startAt, e.endAt, 'day', '[]')).map(e => ({...e})) || []
                        const offsetFraction = 100 / Math.max(5, todayEvents.length)
                        return (
                            <Col onMouseUp={e => newEvent ? this.selectByDrag(e, day, true) : undefined}
                                 span={Math.floor(21 / days.length)}
                                 onMouseDown={e => this.selectByDrag(e, day)}
                                 onMouseMove={newEvent ? (e) => this.selectByDrag(e, day) : undefined}
                                 className={'text-center border-left position-relative ' + this.buildColumnClass(day)}
                                 style={{height: HOUR_HEIGHT * 24 + 'px', cursor: newEvent ? 'move' : 'pointer'}}>
                                {todayEvents.map((e, index) => {
                                            const overlapping = todayEvents?.filter(secE => secE.startAt <= e.startAt && secE.key !== e.key)
                                                .filter(secE => e.endAt && secE.endAt && secE.startAt <= e.endAt && secE.endAt >= e.startAt)
                                    const offset = overlapping.length ? (overlapping.pop()?.offset || 0) + 1 : 0
                                        todayEvents[index].offset = offset


                                        const dayEnd = e.endAt?.isAfter(day.clone().endOf('day')) ? day.clone().endOf('day') : e.endAt
                                            const dayStart = e.startAt?.isBefore(day.clone().startOf('day')) ? day.clone().startOf('day') : e.startAt


                                    console.log(e.name, offset)

                                        return (
                                                <Tag className={'position-absolute border prevent-select m-0 p-0'}
                                                     onMouseUp={e => !newEvent && e.stopPropagation()}
                                                     onMouseDown={e => !newEvent && e.stopPropagation()}
                                                     style={{
                                                         transition: 'none',
                                                         ...(Math.abs(dayStart.diff(dayEnd, 'h', true)) ? {height: Math.abs(dayStart.diff(dayEnd, 'h', true)) * HOUR_HEIGHT + 'px'} : {}),
                                                         top: dayStart.diff(dayStart.clone().startOf('D'), 'h', true) * HOUR_HEIGHT + 'px',
                                                         left: offset * offsetFraction + '%',
                                                         width: 100 - (offset * offsetFraction) + '%',
                                                         zIndex: index
                                                     }} color={e.color}>
                                                    {eventRender ? eventRender(e) : (
                                                        <span>
                                                            {e.startAt.format('LT') + " "}{e.label}
                                                        </span>)}
                                                </Tag>
                                            )
                                        }
                                    )}
                            </Col>
                        )}
                    )}
                </Row>
            </div>
        )
    }
}

export default Days