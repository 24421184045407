import {
    SortableContainer as sortableContainer,
    SortableElement as sortableElement,
    SortableHandle as sortableHandle
} from 'react-sortable-hoc';
import React from "react";
import {Empty} from "antd";
import {TweenOneGroup} from "rc-tween-one";

export type DragSortListItem<T = any> = {
    render: (item: T, index: number, handle: JSX.Element) => JSX.Element
    className?: string,
    style?: React.CSSProperties
}

export type DragSortListHandle<T = any> = {
    render: (item: T, index: number) => JSX.Element
    className?: string,
    style?: React.CSSProperties
}

interface ISharedProps {
    handle?: DragSortListHandle
}

interface IProps extends ISharedProps {
    children: any[],
    item: DragSortListItem
    showEmpty?: boolean
    className?: string
}

const DragSortList = sortableContainer(({children, item, handle, showEmpty, className}: IProps) => {
    return (
        <div className={"position-relative"}>
            <TweenOneGroup
                className={'position-relative ' + (className || '')}
                enter={{scale: 0.8, opacity: 0, type: 'from', duration: 100}}
                leave={{y: -20, opacity: 0, duration: 300}}
                appear={false}
            >
                {children.map((value, index) => {
                    return (
                        <SortableItem key={index} index={index} dataIndex={index} data={value} item={item}
                                      handle={handle}/>
                    )
                })}
            </TweenOneGroup>
            {(showEmpty === undefined || showEmpty) && children.length === 0 &&
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'žádné položky'}/>}
        </div>
    )
})

interface IElementProps extends ISharedProps {
    data: any
    index: number
    dataIndex: number
    item: DragSortListItem
}


const SortableItem = sortableElement(({item, data, dataIndex, handle}: IElementProps) => {
    return (
        <div className={item.className || ''} style={{backgroundColor: 'white', cursor: 'move', ...item?.style}}>
            {item.render(data, dataIndex, <DragHandle handle={handle} data={data} dataIndex={dataIndex}/>)}
        </div>
    )
})

interface IHandleProps extends ISharedProps {
    data: any,
    dataIndex: number
}

const DragHandle = sortableHandle(({handle, data, dataIndex}: IHandleProps) => {

    return (
        <div className={handle?.className || ''} style={{backgroundColor: 'white', cursor: 'move', ...handle?.style}}>
            {handle?.render(data, dataIndex)}
        </div>
    )
})

export default DragSortList


