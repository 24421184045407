import {
    SETUP_BOOKMARKS_UPDATE,
    SETUP_END_LOADING,
    SETUP_LOAD,
    SETUP_LOCATION_STORE,
    SETUP_RELOAD,
    SETUP_SAVE,
    SETUP_START_LOADING,
    SETUP_UPDATE,
    SETUP_USER_UPDATE
} from '../constants/Setup';
import IRoute from "../../model/interface/dataStorage/IRoute";
import IMenu from "../../model/interface/ui/IMenu";
import IView from "../../model/interface/dataStorage/IView";
import IContentType from "../../model/interface/dataStorage/IContentType";
import IModule from "../../model/interface/dataStorage/IModule";
import IUser from "../../model/interface/security/IUser";
import IForm from "../../model/interface/form/IForm";
import IFieldType from "../../model/interface/dataStorage/IFieldType";
import IExtensionType from "../../model/interface/dataStorage/IExtensionType";
import IScript from "../../model/interface/dataStorage/IScript";
import IWorkflow from "../../model/interface/dataStorage/workflow/IWorkflow";
import ISettings from "../../model/interface/ui/ISettings";
import IBookmark from "../../model/interface/ui/IBookmark";
import selectors from "../selectors";
import IEmployee from "../../model/interface/company/IEmployee";
import ICompositeFieldType from "../../model/interface/dataStorage/field/ICompositeFieldType";
import ISettingsOptions from "../../model/interface/security/ISettingsOptions";
import IAdditionalAuthorization from "../../model/interface/security/IAdditionalAuthorization";
import ITerm from "../../model/interface/ui/dictionary/ITerm";
import ILanguage from "../../model/interface/ui/dictionary/ILanguage";
import ICredentialOption from "../../model/interface/security/ICredentialOption";

export type ISetupState = {
    loading: boolean,
    loaded: boolean,
    modules: IModule[],
    routes: IRoute[],
    routesIndex: { [x: string]: IRoute },
    menu: IMenu[],
    views: IView[],
    contentTypes: IContentType[],
    contentTypesIndex: { [x: string]: IContentType },
    workflows: IWorkflow[],
    extensionTypes: IExtensionType[],
    fieldTypes: IFieldType[],
    compositeFieldTypes: ICompositeFieldType[],
    forms: IForm[],
    scripts: IScript[],
    user: IUser,
    originalUser?: IUser,
    delegatedBy: IEmployee[]
    masqueradeActive: boolean,
    masterClasses: string[]
    credentialOptions: ICredentialOption[]
    settingsOptions: ISettingsOptions
    settings: ISettings
    pushNotificationPublicKey: string
    bookmarks: IBookmark[]
    locations: string[],
    licensed: boolean,
    additionalAuthorizations: IAdditionalAuthorization[],
    languages: ILanguage[]
    terms: ITerm[]
    licenseExpiryDate: string,
    version: string
}

type IAction = {
    type: string,
    [x: string]: any
}

const initState: ISetupState = {
    loading: false,
    loaded: false,
    modules: [],
    routes: [],
    routesIndex: {},
    menu: [],
    views: [],
    contentTypes: [],
    forms: [],
    scripts: [],
    workflows: [],
    contentTypesIndex: {},
    extensionTypes: [],
    fieldTypes: [],
    compositeFieldTypes: [],
    user: null as unknown as IUser,
    originalUser: null as unknown as IUser,
    delegatedBy: [],
    masqueradeActive: false,
    masterClasses: [],
    credentialOptions: [],
    settingsOptions: {},
    settings: {} as ISettings,
    pushNotificationPublicKey: '',
    bookmarks: [],
    locations: [],
    licensed: true,
    additionalAuthorizations: [],
    languages: [],
    terms: [],
    licenseExpiryDate: '',
    version: ''
}

const setup = (state = initState, action: IAction) => {
    switch (action.type) {
        case SETUP_START_LOADING:
        case SETUP_END_LOADING:
            return {
                ...state,
                loading: action.loading
            }
        case SETUP_LOAD: {
            return {
                ...state
            }
        }
        case SETUP_RELOAD: {
            return {
                ...state,
                loaded: false
            }
        }
        case SETUP_SAVE: {
            return {
                ...state,
                modules: action.modules,
                menu: action.menu,
                routes: action.routes,
                routesIndex: buildRoutesIndex(action.routes),
                views: action.views,
                contentTypes: action.contentTypes
                    .map((c: IContentType) => ({
                        ...c,
                        fields: c.fields.filter(f => !f.targetEntity || selectors.services.isVirtual(f.targetEntity) || selectors.services.findOneOrNullByFullClassName({setup: action}, f.targetEntity))
                    })),
                contentTypesIndex: buildContentTypeIndex(action.contentTypes),
                workflows: action.workflows,
                extensionTypes: action.extensionTypes,
                fieldTypes: action.fieldTypes,
                compositeFieldTypes: action.compositeFieldTypes,
                forms: action.forms,
                scripts: action.scripts,
                user: action.user,
                originalUser: action.originalUser,
                delegatedBy: action.delegatedBy,
                masqueradeActive: action.masqueradeActive,
                loaded: true,
                loading: false,
                masterClasses: action.masterClasses,
                credentialOptions: action.credentialOptions,
                settingsOptions: action.settingsOptions,
                settings: action.settings,
                pushNotificationPublicKey: action.pushNotificationPublicKey,
                bookmarks: action.bookmarks,
                licensed: action.licensed,
                additionalAuthorizations: action.additionalAuthorizations,
                languages: action.languages,
                terms: action.terms,
                licenseExpiryDate: action.licenseExpiryDate,
                version: action.version
            }
        }
        case SETUP_UPDATE: {
            return {
                ...state,
                ...action
            }
        }
        case SETUP_USER_UPDATE: {
            return {
                ...state,
                user: action.user
            }
        }
        case SETUP_BOOKMARKS_UPDATE: {
            return {
                ...state,
                bookmarks: action.bookmarks
            }
        }
        case SETUP_LOCATION_STORE:
            const last = state.locations.slice(-1)[0]
            if (last !== action.location) {
                state.locations.push(action.location)
            }
            return state
        default:
            return state;
    }
}


const buildRoutesIndex = (routes: IRoute[]) => {
    let output: { [x: string]: IRoute } = {}
    routes.forEach((route: IRoute) => {
        output[route.url] = route
    })
    return output
}

const buildContentTypeIndex = (contentTypes: IContentType[]) => {
    let output: { [x: string]: IContentType } = {}
    if (contentTypes !== undefined) {
        contentTypes.forEach(contentType => {
            output[contentType.id] = contentType
        })
    }
    return output
}

export default setup