import React from 'react'
import {Route, RouteComponentProps, Switch,} from "react-router-dom";
import AuthViews from 'components/auth/index';
import ISettings from "../../model/interface/ui/ISettings";
import {DirectionType} from "antd/es/config-provider";
import Logo from "../layout/Logo";
import {Card, Col, Row} from "antd";

// import { useThemeSwitcher } from "react-css-theme-switcher";

interface IProps extends RouteComponentProps<any>{
	settings: ISettings,
	direction: DirectionType
}
export const AuthLayout = (viewProps: IProps) => {
	const getBackgroundColor = () => {
		return viewProps.settings?.ui?.background || '#ccc';
	}

	return (
		<div className="auth-container">
			<div className="h-100" style={{backgroundColor: getBackgroundColor()}}>
				<div className="container d-flex flex-column justify-content-center h-100">
					<Row justify="center">
						<Col style={{maxWidth: 420}}>
							<Card className={"p-2"}>
								<div className="d-flex justify-content-center">
									<Logo login={true} square={false} isMobile={false} settings={viewProps.settings}
										  className={"logo-big p-0"}/>
								</div>
								<Switch>
									<Route path="" render={(props) => <AuthViews {...props} {...viewProps} /> } />
								</Switch>
							</Card>
						</Col>
					</Row>
				</div>
			</div>

		</div>
	)
}


export default AuthLayout
