import IType from "./workload/IType";

export const COMPANY_SHIFT_TYPE_EACH_WORK_DAY = 'work_day'
export const COMPANY_SHIFT_TYPE_DAILY = 'daily'
export const COMPANY_SHIFT_TYPE_WEEKLY = 'weekly'
export const COMPANY_SHIFT_TYPE_MONTHLY = 'monthly'
export const COMPANY_SHIFT_TYPE_YEARLY = 'yearly'
export const COMPANY_SHIFT_TYPE_ON_HOLIDAY = 'on_holiday'

export default interface IShift {
    id?: number,
    uuid: string,
    startAtTime: string,
    endAtTime: string,
    type: "daily"|"weekly"|"work_day"|"monthly"|"yearly"| "on_holiday",
    value: number|null,
    startAtTimeHours: number,
    startAtTimeMinutes: number,
    endAtTimeHours: number,
    endAtTimeMinutes: number,
    totalHours: number
    entryType: IType|string|null
}