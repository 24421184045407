import React from "react";
import {Button, Modal as AntdModal, Tabs} from "antd";
import ICard from "../../../../../model/interface/dataStorage/ICard";
import CardProperties from "./CardProperties";
import IBaseProps from "../../../../../model/interface/IBaseProps";
import CardEditor from "./CardEditor";
import {BarsOutlined, FormOutlined, OrderedListOutlined, SaveOutlined} from "@ant-design/icons";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import Utils from "../../../../../utils";
import CardEvaluation from "./CardEvaluation";
import Modal from "../../../../shared/modal/Modal";
import {FormConfigurationStep} from "../../form/FormConfiguration";

interface IState {
    saving: boolean
    showAll: boolean
    card: ICard
    step: CardConfigurationStep
    changedCards: ICard[]
    valid: boolean
}

interface IProps extends IBaseProps {
    onFinish?: (card?: ICard, cards?: ICard[]) => void
    card?: ICard,
    contentType: IContentType,
    step?: CardConfigurationStep
}

export interface ICardStepProps extends IBaseProps {
    onChange: (card: ICard, stepBack?: boolean, cards?: ICard[]) => void
    card: ICard
    setValid: (valid: boolean) => void
}

export type CardConfigurationStep = 'properties' | 'evaluation' | 'editor' | string


class CardModal extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            saving: false,
            showAll: false,
            valid: true,
            card: {
                ...props.card ? props.card : {
                    name: '',
                    widgets: [],
                    route: undefined,
                    uuid: Utils.uuid(),
                    routePriority: Math.max(...this.props.contentType.cards.map(c => c.routePriority || 0))
                },
                contentType: props.contentType.uuid
            },
            changedCards: [],
            step: props.step || 'properties'
        }
    }

    onChange = (card: ICard, stepBack?: boolean, cards?: ICard[]) => {
        this.setState(state => ({card, changedCards: cards || state.changedCards}), () => this.setStep('', stepBack))
    }

    setStep = (step: FormConfigurationStep, stepBack?: boolean) => {
        const currentIndex = this.getSteps().findIndex(s => s.key === (step || this.state.step))
        this.setState(state => ({
            step: step ? step :
                stepBack !== undefined ? this.getSteps()[currentIndex + (stepBack ? -1 : 1)]?.key : state.step
        }))
    }

    onCancel = () => {
        this.state.card && JSON.stringify(this.props.card) !== JSON.stringify(this.state.card) ? AntdModal.confirm({
            title: 'Pozor, pokud budete pokračovat, všechny změny budou ztraceny!',
            onOk: () => {
                AntdModal.destroyAll();
                this.updateParent()
            },
            onCancel() {
            },
            type: 'warning'
        }) : this.updateParent()
    }

    onFinish = () => {
        this.setState({saving: true}, () => {
            const {card, changedCards} = this.state
            if (card) {
                this.updateParent(card, changedCards);
            }
        })
    }

    updateParent(card?: ICard, cards?: ICard[]) {
        this.props.onFinish && this.props.onFinish(card, cards)
    }

    setValid = (valid: boolean) => {
        this.setState({valid})
    }

    render() {
        const {saving, card, step, valid} = this.state

        const tabs = this.getSteps()

        return (
            <Modal
                fullScreenOption={true}
                title={card.id ? `Uprava karty [${card.name}]` : 'Nová karta'}
                visible={true}
                bodyStyle={{height: '60vh', overflow: "auto"}}
                onCancel={this.onCancel}
                width={'80vw'}
                destroyOnClose={true}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        zrušit
                    </Button>,
                    <Button icon={<SaveOutlined/>} key="submit" type="primary" onClick={() => this.onFinish()}
                            disabled={Number(step) < tabs.length || !valid} loading={saving}>
                        Uložit
                    </Button>
                ]}
            >
                <Tabs className={'h-100 content-h-100'} activeKey={step} onChange={k => k && this.setStep(k)}>
                    {tabs.map((tab, index) => (
                        <Tabs.TabPane className={'h-100'} tab={<div>{tab.icon} {tab.label}</div>} key={tab.key}
                                      disabled={!card.id && index > tabs.findIndex(t => t.key === step)}>
                            {tab.widget}
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </Modal>
        )
    }

    getSteps() {
        const {card} = this.state
        const {history, match, contentType} = this.props

        const sharedProps = {card, onChange: this.onChange, history, match, contentType, setValid: this.setValid}

        return [
            {
                label: 'Vlastnosti',
                key: 'properties',
                icon: <BarsOutlined/>,
                widget: <CardProperties {...sharedProps}/>
            },
            {
                label: 'Vyhodnocení',
                key: 'evaluation',
                icon: <OrderedListOutlined/>,
                widget: <CardEvaluation {...sharedProps}/>
            },
            {
                label: 'Editor',
                key: 'editor',
                icon: <FormOutlined/>,
                widget: <CardEditor {...sharedProps}/>
            }
        ];
    }
}

export default CardModal