import React from "react";
import {Col, Tooltip} from "antd";
import {connect} from "react-redux";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import INotification, {STATES} from "model/interface/ui/INotification"
import {changeState} from "../../../../redux/actions/Notifications";
import Button, {ButtonSizes} from "../../../shared/button/Button";

interface IState {
    changingState?: number
}

interface IProps {
    notification: INotification
    size?: ButtonSizes
    changeState: (notification: INotification, state: number) => void,
}

class NotificationActions extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {}
    }

    changeState(notification: INotification, state: number, e: React.MouseEvent<HTMLElement, MouseEvent>) {
        this.setState({changingState: state})
        this.props.changeState(notification, state)
        e.stopPropagation()
        e.preventDefault()
        return false
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.notification.state === STATES.STATE_LOADING && this.props.notification.state !== STATES.STATE_LOADING){
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
            }
            this.setState({changingState: undefined})
        }
    }

    render() {
        const {notification, size} = this.props
        const {changingState} = this.state
        const loading = notification.state === STATES.STATE_LOADING

        return <>
            <Col>
                {notification.state === STATES.STATE_VISITED || changingState === STATES.STATE_NEW ? (
                    <Tooltip title={"Znovu označit jako nepřečtené"}>
                        <Button size={size} icon={<CheckOutlined/>}
                                loading={loading && changingState === STATES.STATE_NEW}
                                onClick={e => this.changeState(notification, STATES.STATE_NEW, e)}/>
                    </Tooltip>
                ) : (
                    <Tooltip title={"Označit jako přečtené"}>
                        <Button size={size} type={"success"} icon={<CheckOutlined/>}
                                loading={loading && changingState === STATES.STATE_VISITED}
                                onClick={e => this.changeState(notification, STATES.STATE_VISITED, e)}/>
                    </Tooltip>
                )}
            </Col>
            <Col>
                <Tooltip title={"Vymazat upozornění"}>
                    <Button size={size} type={"danger"} icon={<CloseOutlined/>}
                            loading={loading && changingState === STATES.STATE_DELETED}
                            onClick={e => this.changeState(notification, STATES.STATE_DELETED, e)}/>
                </Tooltip>
            </Col>
        </>
    }
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {
    changeState
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationActions)
