export const COMPANY_WORKLOAD_TYPE_MODE_WORKING = 1
export const COMPANY_WORKLOAD_TYPE_MODE_REST = 2
export const COMPANY_WORKLOAD_TYPE_MODE_READY = 3
export const COMPANY_WORKLOAD_TYPE_MODE_OTHER = 9

export default interface IType {
    id?: number,
    uuid: string,
    title: string,
    shortcut: string | null,
    mode: number,
    requiresBreak: boolean,
    approvalRequired: boolean,
    unlimitedFund: boolean,
    noteRequired: boolean,
    attachmentRequired: boolean,
    description: string | null,
    color: string | null,
    allowOvertime: boolean | null
    acceptanceOfOverdue?: boolean | null
    rejectionOfOverdue?: boolean | null
}