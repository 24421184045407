import React from "react"
import {Tooltip} from "antd";
import Button from "../../../../shared/button/Button";
import {DATE_FORMAT_YYYY_MM_DD_HH_mm_ss} from "../../../../../model/constants/DateConstant";
import {PlusOutlined} from "@ant-design/icons";
import moment, {Moment} from "moment/moment";
import IEntry from "../../../../../model/interface/company/workload/IEntry";
import EntriesService from "../../../../../model/service/company/workload/EntriesService";
import IType, {COMPANY_WORKLOAD_TYPE_MODE_WORKING} from "../../../../../model/interface/company/workload/IType";
import SettingsService from "../../../../../model/service/SettingsService";
import {ButtonSize} from "antd/es/button";

interface IProps {
    employee: string
    types: IType[]
    date?: Moment
    onCreate?: (entry: IEntry, action: 'create' | 'update') => void,
    customRender?: (onClick: (entry?: IEntry) => void, creating: boolean) => React.ReactNode
    disabled?: boolean
    minStart?: Moment
    size?: ButtonSize
}

interface IState {
    creating: boolean
}

class EntryCreateButton extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            creating: false
        }
    }

    createEntry = (entry?: IEntry) => {
        const {employee, date, onCreate, minStart, types} = this.props
        this.setState({creating: true})
        if (entry) {
            return EntriesService.getInstance().resourceUpdate(entry.id, {
                ...entry,
                endAt: entry.endAt || this.getDateWithCurrentTime(date || moment())
                    .seconds(0).format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss),
            }).then((value) => {
                this.setState({creating: false})
                onCreate?.(value, 'update')
                return value
            })
        }
        const defaultType = SettingsService.getValue('company', 'report_default_entry_type')
            || types.find(t => t.mode === COMPANY_WORKLOAD_TYPE_MODE_WORKING && t.unlimitedFund && !t.approvalRequired)?.uuid
        if (!defaultType) {
            throw new Error('Work entry type could not be determined !')
        }
        return EntriesService.getInstance().collectionCreate({
            employee,
            type: defaultType,
            startAt: (minStart || this.getDateWithCurrentTime(date || moment()))
                .seconds(0).format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss)
        }).then((value) => {
            this.setState({creating: false})
            onCreate?.(value, 'create')
            return value
        })
    }

    getDateWithCurrentTime(date: Moment, time?: Moment) {
        return (time || moment()).year(date.year()).month(date.month()).date(date.date())
    }

    render() {
        const {creating} = this.state
        const {customRender, disabled, size} = this.props
        return (
            customRender ? customRender(this.createEntry, creating) : <Tooltip title={'Příchod'}>
                <Button icon={<PlusOutlined/>}
                        shape={'circle'}
                        disabled={disabled}
                        loading={creating}
                        onClick={() => this.createEntry()}
                        size={size}
                        className={"btn-outlined"}/>
            </Tooltip>
        )
    }
}


export default EntryCreateButton
