import React from 'react';
// import logo from './assets/images/proces_logo.png';
// import './App.css';
// import './assets/stylesheets/index.css';
import './assets/stylesheets/light-theme.css';

// import Hello from './components/Hello';
// import Test from './components/Test';
import {Provider} from 'react-redux';
import store from './redux/store';
// import { ThemeSwitcherProvider } from "react-css-theme-switcher";
// import { THEME_CONFIG } from './configs/AppConfig';
// import AppLayout from "./layouts/app-layout";
// import Views from './components';
import {Route, Router, Switch} from 'react-router-dom';
import Components from "./components/Components";
import history from "./history";

// const themes = {
//     dark: `${process.env.PUBLIC_URL}/stylesheets/dark-theme.css`,
//     light: `${process.env.PUBLIC_URL}/stylesheets/light-theme.css`,
// };

function App() {
    document.body.className += ' dir-ltr'

    return (
        <div className="App h-100">
            <Provider store={store}>
                {/*<ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">*/}
                <Router history={history}>
                    <Switch>
                        <Route path="/" component={Components}/>
                    </Switch>
                </Router>
                {/*</ThemeSwitcherProvider>*/}
            </Provider>
            {/*<header className="App-header">*/}
            {/*  <img src={logo} className="App-logo" alt="logo" />*/}
            {/*  <p>*/}
            {/*    Edit <code>src/App.tsx</code> and save to reload.*/}
            {/*  </p>*/}
            {/*  <a*/}
            {/*    className="App-link"*/}
            {/*    href="https://reactjs.org"*/}
            {/*    target="_blank"*/}
            {/*    rel="noopener noreferrer"*/}
            {/*  >*/}
            {/*    Foo bar*/}
            {/*  </a>*/}
            {/*  <Hello />*/}
            {/*  <Test />*/}
            {/*</header>*/}
        </div>
    );

}


export default App;
