import {
    MARGIN_CHANGE,
    TOGGLE_COLLAPSED_NAV,
    CHANGE_LOCALE,
    NAV_TYPE_CHANGE,
    TOGGLE_MOBILE_NAV,
    FONT_SIZE_CHANGE,
    TABLE_SIZE_CHANGE, LOAD_THEME, IS_MOBILE_CHANGE, HEADER_HEIGHT,
} from '../constants/Theme';
import {THEME_CONFIG} from 'configs/AppConfig'
import {ITheme} from "../../model/interface/security/ITheme";

const initTheme = {
    ...THEME_CONFIG
};

export type IThemeState = {
    type: string
    margin: string
    tableSize: string
    fontSize: string
    locale: string
    navCollapsed: boolean,
    sideNavTheme: string
    mobileNav: boolean
    navType: string,
    theme: ITheme
    isMobile: boolean
    direction?: string,
    currentTheme?: string
    headerNavColor?: string
    headerHeight?: number
}

const theme = (state = initTheme, action: IThemeState) => {
    switch (action.type) {
        case LOAD_THEME:
            return {
                ...state,
                margin: action.theme.margin || state.margin,
                fontSize: action.theme.fontSize || state.fontSize,
                tableSize: action.theme.tableSize || state.tableSize
            }
        case MARGIN_CHANGE:
            return {
                ...state,
                margin: action.margin
            }
        case TABLE_SIZE_CHANGE:
            return {
                ...state,
                tableSize: action.tableSize
            }
        case HEADER_HEIGHT:
            return {
                ...state,
                headerHeight: action.headerHeight
            }
        case FONT_SIZE_CHANGE:
            return {
                ...state,
                fontSize: action.fontSize
            }
        case TOGGLE_COLLAPSED_NAV:
            return {
                ...state,
                navCollapsed: action.navCollapsed
            };
        // case SIDE_NAV_STYLE_CHANGE:
        //   return {
        //     ...state,
        //     sideNavTheme: action.sideNavTheme
        //   };
        case CHANGE_LOCALE:
            return {
                ...state,
                locale: action.locale
            };
        case NAV_TYPE_CHANGE:
            return {
                ...state,
                navType: action.navType
            };
        // case TOP_NAV_COLOR_CHANGE:
        //   return {
        //     ...state,
        //     topNavColor: action.topNavColor
        //   };
        // case HEADER_NAV_COLOR_CHANGE:
        //   return {
        //     ...state,
        //     headerNavColor: action.headerNavColor
        //   };
        case TOGGLE_MOBILE_NAV:
            return {
                ...state,
                mobileNav: action.mobileNav
            };
        case IS_MOBILE_CHANGE:
            return {
                ...state,
                isMobile: action.isMobile
            };
        // case SWITCH_THEME:
        //   return {
        //     ...state,
        //     currentTheme: action.currentTheme
        //   }
        // case DIRECTION_CHANGE:
        //   return {
        //     ...state,
        //     direction: action.direction
        //   }
        default:
            return state;
    }
};

export default theme