import React, {Component} from 'react';
import {ColorResult, SketchPicker} from "react-color";
import {Button, Col, Dropdown, Row} from "antd";
import {CloseOutlined} from "@ant-design/icons";

interface IProps {
    value?: string
    onChange?: (key?: string | null) => void
    className?: string
    placeholder?: string
    disabled?: boolean
    output?: 'hex' | 'rgba',
    allowClear?: boolean
}

interface IState {
    value?: string
    visible?: boolean
}

class ColorPicker extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    static defaultProps = {
        output: 'hex',
        allowClear: true
    }

    onColorChange = (value: ColorResult) => {
        const {onChange, output} = this.props
        let result
        const {rgb} = value
        if (output === 'hex') {
            result = '#' + ((1 << 24) | (rgb.r << 16) | (rgb.g << 8) | rgb.b).toString(16).slice(1)
                + (rgb.a ? (Math.round(rgb.a * 255) | (1 << 8)).toString(16).slice(1) : '')
        } else {
            result = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
        }
        onChange && onChange(result)
    }

    parseValue(output: "hex" | "rgba" | undefined, value?: string) {
        if (!value) {
            return undefined
        }
        if (output === 'hex') {
            const c = value.slice(1).match(/.{1,2}/g);
            const rgb: number[] = [];
            const a = c?.[3] ? parseFloat((parseInt(((parseInt(c[3], 16) / 255) * 1000) + "") / 1000) + '') : 1
            c?.slice(0, 3).forEach(v => rgb.push(parseInt(v, 16)))
            return {
                r: rgb[0],
                g: rgb[1],
                b: rgb[2],
                a: a
            }
        } else {
            const digits = value.match(/[.?\d]+/g)
            return {
                r: digits?.[0] ? Number(digits[0]) : 0,
                g: digits?.[1] ? Number(digits[1]) : 0,
                b: digits?.[2] ? Number(digits[2]) : 0,
                a: digits?.[3] ? parseFloat(digits[3]) : 1
            }
        }
    }

    onClear = () => {
        this.props.onChange?.(null)
    }

    render() {
        const {value, disabled, placeholder, output, allowClear, className} = this.props
        let parsedValue = this.parseValue(output, value);

        return (
            <Dropdown disabled={disabled} trigger={["click"]} overlay={
                <SketchPicker color={parsedValue} onChange={this.onColorChange}/>
            }>
                <Button className={(className || '') + ' w-100 ant-input'}>
                    <Row justify={"space-between"} align={'middle'} className={'h-100'}>
                        <Col className={'h-100'} style={{backgroundColor: value || '#ffffff'}} flex={'1 1'}>{placeholder || ''}</Col>
                        {allowClear && (
                            <CloseOutlined onClick={e => {
                                e.stopPropagation()
                                this.onClear()
                            }} className={'cursor-pointer pl-2'}/>
                        )}
                    </Row>
                </Button>

            </Dropdown>
        )
    }
}

export default ColorPicker
