import React from "react"
import {Spin} from "antd";
import {Moment} from "moment";
import Header from "./Header";
import Days from "./Days";
import MonthYear from "./MonthYear";
import IFreeDay from "../../../model/interface/company/workload/IFreeDay";
import FreeDaysService from "../../../model/service/company/workload/FreeDaysService";

export type CalendarMode = 'year' | 'month' | 'week' | 'day'

export interface IEvent {
    startAt: Moment,
    endAt?: Moment,
    color: string,
    label: string | JSX.Element,
    key: string | number,
    offset?: number
    [name: string]: any
}

interface IProps {
    modes?: CalendarMode[],
    date: Moment
    customHeader?: JSX.Element
    customHeaderItems?: JSX.Element[]
    loading?: boolean,
    onChange?: (date: Moment, min: Moment, max: Moment, mode: CalendarMode) => void,
    onSelect?: (start: Moment, end: Moment) => void,
    defaultMode?: CalendarMode,
    eventRender?: <T extends IEvent>(object: T) => string | JSX.Element
    events?: IEvent[]
    className?: string
    highlightWeekend?: boolean
    highlightHolidays?: boolean
}

export interface ICalendarSharedProps{
    date: Moment
    cell?: (date: Moment) => JSX.Element
    onSelect?: (start: Moment, end: Moment) => void
    eventRender?: <T extends IEvent>(object: T) => string | JSX.Element
    events?: IEvent[]
    freeDays?: IFreeDay[]
    highlightWeekend?: boolean
    highlightHolidays?: boolean
}

interface IState {
    mode: CalendarMode,
    fullScreen: boolean
    freeDays: IFreeDay[]
    lastShownYear: number
    loadingFreeDays?: boolean
}

class Calendar extends React.Component<IProps, IState> {

    container: React.RefObject<HTMLDivElement>;

    constructor(props: IProps) {
        super(props);
        this.state = {
            mode: props.defaultMode || 'month',
            fullScreen: false,
            freeDays: [],
            lastShownYear: props.date.year()
        }
        this.container = React.createRef();
    }

    static defaultProps = {
        loading: false
    }

    componentDidMount() {
        const {date} = this.props;
        const {lastShownYear} = this.state
        this.loadFreeDays(lastShownYear)
        this.onChange(date)
    }

    loadFreeDays(year?: number) {
        this.setState({loadingFreeDays: true})
        return FreeDaysService.collectionList(year).then(response => {
            this.setState({freeDays: response.results, loadingFreeDays: false})
        })
    }

    onChange = (date: Moment, mode?: CalendarMode) => {
        const {onChange} = this.props;
        const {lastShownYear} = this.state
        if (mode) {
            this.setState({mode: mode})
        } else {
            mode = this.state.mode
        }
        let min: Moment, max: Moment
        switch (mode) {
            case 'month':
                min = this.getMinMonthDate(date)
                max = this.getMaxMonthDate(date)
                break
            default:
                min = date.clone().startOf(mode)
                max = date.clone().endOf(mode)
        }
        if (lastShownYear !== max.year() || lastShownYear !== min.year()) {
            this.loadFreeDays(max.year()).then(() => {
                this.setState({lastShownYear: max.year()})
            })
        }
        onChange?.(date, min, max, mode)
    }

    goFullScreen = () => {
        const {fullScreen} = this.state
        const elem = this.container.current as any
        this.setState({fullScreen: !fullScreen})
        if (fullScreen) {
            document.exitFullscreen().then()
        } else {
            elem?.requestFullscreen().then()
        }
    }

    getMinMonthDate(month: Moment) {
        const weekDay = month.clone().startOf('month').weekday()

        return month.clone().startOf('month').subtract(weekDay + 1, "d")
    }

    getMaxMonthDate(month: Moment) {
        return this.getMinMonthDate(month).add(43, 'd')
    }

    render() {
        const {mode, fullScreen, freeDays, loadingFreeDays} = this.state;
        const {
            customHeader,
            customHeaderItems,
            loading,
            date,
            events,
            eventRender,
            modes,
            onSelect,
            className,
            highlightHolidays,
            highlightWeekend
        } = this.props;
        const sharedProps: ICalendarSharedProps = {
            date,
            eventRender,
            events: events?.sort((a, b) => a.startAt.valueOf() - (b.startAt?.valueOf() || 0)),
            onSelect: onSelect,
            freeDays,
            highlightHolidays,
            highlightWeekend
        }

        return (
            <div ref={this.container} className={(className || '') + (fullScreen ? ' bg-white' : '')}
                 style={{overflowY: "auto", overflowX: "hidden"}}>
                <Spin spinning={loading || loadingFreeDays}>
                    {customHeader || <Header date={date} mode={mode} modes={modes} customItems={customHeaderItems}
                                             onChange={this.onChange} fullScreenChange={this.goFullScreen}
                                             fullScreen={fullScreen}/>}
                    <div className={'border rounded overflow-auto'}>
                        {{
                            month: <MonthYear mode={'month'} {...sharedProps}/>,
                            year: <MonthYear mode={'year'} {...sharedProps}/>,
                            week: <Days mode={'week'} {...sharedProps}/>,
                            day: <Days mode={'single'} {...sharedProps}/>
                        }[mode]}
                    </div>
                </Spin>
            </div>
        )
    }
}

export default Calendar