import React from "react";
import IWidgetProps from "../../../../model/interface/widget/IWidgetProps";
import IWidgetPropsFunctions from "../../../../model/interface/widget/IWidgetPropsFunctions";
import IIframeOptions from "../../../../model/interface/widget/option/IIframeOptions";


class IframeWidget extends React.Component<IWidgetProps<IWidgetPropsFunctions, IIframeOptions>> {

    isValidHttpUrl(text?: string) {
        let url;

        try {
            url = new URL(text || '');
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    render() {
        const {url, height} = this.props.options

        return (this.isValidHttpUrl(url) || this.props.editor) ? (
            <div>
                <iframe src={url} className={'w-100'} height={height} title={this.props.id}/>
            </div>
        ) : ''
    }
}

export default IframeWidget