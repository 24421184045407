import React, {RefObject} from 'react'
import {Form, FormInstance, message} from 'antd';
import _ from "underscore"
import IFormOptions from "../../../../model/interface/form/IFormOptions";
import IFormStructureNode from "../../../../model/interface/form/IFormStructureNode";
import {IFormStepProps} from "./FormConfiguration";
import FormElement from "./FormElement";
import IField, {RELATION_FIELD_TYPE} from "../../../../model/interface/dataStorage/IField";
import IAction from "../../../../model/interface/dataStorage/IAction";
import FormElementType from "./FormElement/FormElementType";
import FormElementList from "./FormElement/FormElementList";
import IWidget from "../../../../model/interface/form/IWidget";
import FieldEditor from "./FormElement/optionEditor/FieldEditor";
import IFormFieldOptions from "../../../../model/interface/form/elementOptions/IFieldOptions";
import IContainerOptions from "../../../../model/interface/form/elementOptions/IContainerOptions";
import ContainerEditor from "./FormElement/optionEditor/ContainerEditor";
import FormFieldType from "./FormElement/formField/FormFieldType";
import SubmitEditor from "./FormElement/optionEditor/SubmitEditor";
import ISubmitOptions from "../../../../model/interface/form/elementOptions/ISubmitOptions";
import {ITreeStructure} from "../../../../utils/TreeStructure";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import Widget from "../widget/Widget";
import FormElementGallery from "./FormElement/FormElementGallery";
import ActionWidgetEditor from "../widget/optionEditor/ActionWidgetEditor";
import IActionOptions from "../../../../model/interface/widget/option/IActionOptions";
import EntityTableEditor from "./FormElement/optionEditor/EntityTableEditor";
import IEntityTableOptions from "../../../../model/interface/form/elementOptions/IEntityTableOptions";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";
import Utils from "../../../../utils";
import {IActionResult} from "../../../../model/service/dataStorage/ActionsService";
import IFormElementFunctions from "../../../../model/interface/form/IFormElementFunctions";
import Editor, {IEditorProps, IEditorState} from "../editor/Editor";
import IWidgetOptions from "../../../../model/interface/widget/IWidgetOptions";
import ReportWidgetType from "../report/widget/ReportWidgetType";

interface IState extends IEditorState<IFormStructureNode> {
    actions: IAction[],
    fields: IField[]
}

interface IProps extends IFormStepProps, IEditorProps {
    findContentType: (name: string) => IContentType
    findContentTypeByUuid: (uuid: string) => IContentType
    setValid: (valid: boolean) => void
}

class FormEditor extends Editor<IProps, IState, IFormStructureNode> {

    formRef: RefObject<FormInstance> = React.createRef()
    widgetList = FormElementList

    constructor(props: IProps) {
        super(props);
        const containerUuid = Utils.uuid()
        this.state = {
            ...this.state,
            current: props.form.widgets.find(w => !w.parent)?.uuid || containerUuid,
            structure: props.form.widgets.length > 0 ? FormEditor.widgetsToFormStructure(props.form.widgets, this.getContentType())
                : {
                    [containerUuid]: {
                        ...this.widgetList.getByType(ReportWidgetType.CONTAINER),
                        id: containerUuid,
                        label: 'Container'
                    }
                },
            fields: [],
            actions: []
        }
    }

    componentDidMount() {
        const {form} = this.props
        const contentType = this.getContentType()
        this.setState({fields: contentType.fields, actions: contentType.actions})
        let structure = FormEditor.widgetsToFormStructure(form.widgets, contentType)
        form.widgets.length > 0 && this.setState(state => ({
                structure,
                current: Object.values(structure).find(n => !n.parent)?.id || state.current
            }),
            this.formRef.current?.resetFields
        )
    }

    static widgetsToFormStructure(widgets: IWidget[], contentType: IContentType) {
        let structure: ITreeStructure<IFormStructureNode> = {}
        widgets.forEach(widget => {
            structure[widget.uuid] = {
                ...widget,
                id: widget.uuid,
                key: widget.uuid,
                children: widgets.filter(value => value.parent === widget.uuid).map(wd => wd.uuid),
                parent: widget.parent,
                field: _.findWhere(contentType.fields, {uuid: widget.field}),
                fieldUuid: widget.field,
                contentTypeUuid: widget.contentTypeUuid,
                action: _.findWhere(contentType.actions, {uuid: widget.action})
            }
        })
        return structure;
    }

    static isWidget(value: any): value is IWidget {
        return value && value.uuid;
    }

    setCurrent = (current: string) => {
        this.formRefOptions.current?.validateFields().then(() => {
            this.setState({current}, this.formRefOptions.current?.resetFields)
        }).catch(() => {
            message.warn('Neplatné vlastnosti, prosím opravte').then();
        })

    }

    onOptionsChange = (values?: IFormOptions) => {
        const node = this.getCurrentNode();
        if (values) {
            values.initialValue = values.type ? FormFieldType.formatFromForm(values.type, values.initialValue) : ''

            if (node.field && values) {
                this.formRef.current?.setFieldsValue({
                    [node.field?.name]: FormFieldType.formatToForm(values.type, values.initialValue)
                })
            }
            let field
            if (node.type === FormElementType.ENTITY_TABLE) {
                field = this.getContentType().fields.find(field => values.fieldId === field.id)
            }
            return this.saveNode({...node, field: field || node.field}, values)
        }
        return Promise.resolve()
    }

    getOptionEditor(node: IFormStructureNode, formRef: RefObject<FormInstance>) {
        let contentType = this.getContentType()
        switch (node.type) {
            case FormElementType.FIELD:
                const nodeFieldUuid = typeof node.field === 'object' ? node.field.uuid : node.fieldUuid
                if (node.contentTypeUuid || node.field?.contentTypeId){
                    contentType = this.getContentType(node.contentTypeUuid || node.field?.contentTypeId)
                }
                let field = contentType.fields.find(field => field.uuid === nodeFieldUuid)
                if (!field) {
                    throw new Error('Field not found')
                }
                return field &&
                    <FieldEditor formRef={formRef} contentType={contentType} fields={this.getStructureFieldNodes()}
                                 field={field}
                                 options={node.options as IFormFieldOptions}
                                 match={this.props.match} history={this.props.history}/>
            case FormElementType.ACTION:
                return <ActionWidgetEditor action={node.action as IAction} formRef={formRef}
                                           options={node.options as IActionOptions}/>
            case FormElementType.CONTAINER:
                return <ContainerEditor formRef={formRef} options={node.options as IContainerOptions}
                                        fieldNodes={this.getStructureFieldNodes()} fields={contentType.fields}
                />
            case FormElementType.SUBMIT:
                return <SubmitEditor options={node.options as ISubmitOptions}/>
            case FormElementType.ENTITY_TABLE:
                return <EntityTableEditor options={node.options as IEntityTableOptions} fields={this.state.fields}/>
            default:
                return Widget.getOptionEditor(node.type, node.options, formRef, this.onOptionsChange)
        }
    }

    getStructureFieldNodes() {
        return Object.entries(this.state.structure)
            .filter(value => value[1].field
                && value[1].options.type
                && this.state.fields
                && typeof this.state.fields.find === 'function'
                && this.state.fields.find(field => value[1].field && field.id === value[1].field.id)
            )
            .map(value => value[1]);
    }

    getGallery = (group?: string) => {
        const {current, structure} = this.state
        let field = structure[current].field
        if (structure[current].type === FormElementType.ENTITY_TABLE){
            group = FormElementType.GROUP_FIELD
            field = this.getContentType().fields.find(f => f.id === structure[current].options.fieldId)
        }
        const contentType = this.getFieldContentType(field);
        return <FormElementGallery node={this.getCurrentNode()} list={FormElementList}
                                   onSelect={(type: string, action, field) =>
                                       this.appendNodeExecute(current, type, action, field)} contentType={contentType}
                                   exists={this.actionOrFieldExists}
                                   customGroup={group}/>
    }

    getFieldContentType(field: IField | undefined) {
        let contentType = this.getContentType()
        if (field && field.targetEntity) {
            contentType = this.props.findContentType(field.targetEntity)
        }
        return contentType
    }

    getContentType(uuid?: string | null) {
        const {form, findContentTypeByUuid} = this.props
        return findContentTypeByUuid(uuid || form.contentType)
        //TODO this loads the contentType from redux not the one being edited with possible changes, maybe pass here the editing one ???
    }

    actionOrFieldExists = (group: string, id: number, type?: string) => {
        return !!Object.entries(this.state.structure)
            .find(value => (type && value[1].type === type) || value[1][group as 'action' | 'field']?.id === id)
    }

    appendNodeExecute(id: string, type: string, action?: IAction, field?: IField) {
        this.validate().then(() => {
            let node = {...FormElementList.getByType(type), parent: id, id: Utils.uuid()}
            node = action ? {...node, action, label: action.label || action.name} : field ? {
                ...node,
                field,
                label: field.label || field.name,
                fieldUuid: field.uuid
            } : node
            this.saveNode(node).then()
        })
    }

    getCurrentValue(field: string) {
        return this.formRef.current?.getFieldValue(field)
    }

    valueUpdated = () => {
        this.forceUpdate()
    }

    saveNode(node: IFormStructureNode, values?: IWidgetOptions) {
        return super.saveNode(node, values).then(this.confirm)
    }

    confirm = () => {
        const {onChange, setValid} = this.props
        this.formRefOptions.current?.validateFields()
            .then(() => setValid(true)).catch(() => setValid(false))
        let {structure} = {...this.state}
        let widgets: IWidget[] = []
        Object.entries(structure).forEach(([, node]) => {
            widgets.push({
                ...node,
                id: null,
                uuid: node.id,
                children: [],
                action: node.action?.uuid,
                field: node.field?.uuid,
                contentTypeUuid: node.field?.contentTypeId
            })
        })
        onChange({...this.props.form, widgets})
    }

    getInitialValues() {
        let initialValues = {} as { [name: string]: any }
        const structure = this.state.structure
        Object.keys(structure).forEach((key) => {
            const fieldNode = structure[key]
            const field = fieldNode.field
            if (field && fieldNode.options && fieldNode.options.initialValue) {
                initialValues[field.name] = FormFieldType
                    .formatToForm(fieldNode.options.type, (fieldNode.options as IFormOptions).initialValue)
            }
        })
        return initialValues
    }

    getInitialOptions(node: IFormStructureNode): IWidgetOptions {
        const {options, type} = node
        switch (type) {
            case FormElementType.FIELD:
                const field = this.getContentType(node.field?.contentTypeId).fields.find(field => node.field?.id === field.id)
                if (field) {
                    let fieldOptions: IFormFieldOptions = {
                        contentTypeFullClassName: field.targetEntity || undefined,
                        initialValue: options.type ? FormFieldType.formatToForm(options.type, options.initialValue) : undefined,
                        label: options.label || field.label || '',
                        type: FieldEditor.detectType(field),
                        ...options
                    }
                    switch (options.type) {
                        case FormFieldType.FIELD_EMPLOYEE:
                            return {employeeMultiple: field.type === RELATION_FIELD_TYPE.MANY_TO_MANY, ...fieldOptions}
                        case FormFieldType.FIELD_CONTENT_TYPE:
                            fieldOptions = {contentTypePresenter: this.getContentType(field.contentTypeId).presenters[0]?.uuid, ...fieldOptions}
                            break
                        case FormFieldType.FIELD_AUTOCOMPLETE:
                            return {autocompleteCollection: '', autocompleteField: '', ...fieldOptions}
                        case FormFieldType.FIELD_TEXT:
                            return {wysiwyg: true, ...fieldOptions}
                    }
                    return fieldOptions
                }
                break;
            case FormElementType.ACTION:
                const action = this.getContentType().actions.find(action => node.action?.id === action.id)
                if (action) {
                    return {
                        label: options.label || action.label || '',
                        ...options
                    }
                }
                break;
            case FormElementType.SUBMIT:
                return {...options, label: options.text}
        }
        return {...super.getInitialOptions(node), ...options};
    }

    renderPreview = () => {
        const {structure, current} = this.state
        const {history, match, form} = this.props
        const initialValues = this.getInitialValues()
        return <Form layout={"vertical"} ref={this.formRef} name={'form_' + form.id}
                     initialValues={initialValues} onValuesChange={this.valueUpdated}>
            {Object.entries(structure).map(([key, node]) => {
                if (node && !node.parent) {
                    let functions = {
                        getNode: (id: string) => structure[id],
                        getContentType: () => this.getContentType(),
                        setCurrent: (id: string) => this.setCurrent(id),
                        getSortedChildren: (id: string) =>
                            FormEditor.getSortedChildren(id, structure),
                        getFormRef: () => this.formRef,
                        getCurrentValue: (field: string) => this.getCurrentValue(field),
                        getResource: () => ({}),
                        onActionStart: () => Promise.resolve({} as IActionResult),
                        onActionFinish: () => Promise.resolve()
                    } as IFormElementFunctions
                    return (
                        <FormElement
                            {...node}
                            editor={true}
                            current={current}
                            id={key}
                            children={node.children}
                            key={key}
                            functions={functions}
                            history={history}
                            match={match}
                        />
                    )
                }
                return null
            })}
        </Form>
    }

    render() {
        return super.render()
    }
}

const mapStateToProps = (state: RootStateOrAny) => {

    return {
        findContentType: (name: string) => selectors.contentTypes.findOneBy(state, 'fullClassName', name),
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(FormEditor)