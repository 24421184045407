import IFilterFunctions from "./Filter/IFilterFunctions";
import SearchFilter from "./Filter/SearchFilter";
import RangeFilter from "./Filter/RangeFilter";
import ChooseFilter from "./Filter/ChooseFilter";
import {IFilterBaseBuild, IFilterInputBuild} from "./Filter/IFilterBase";
import BooleanFilter from "./Filter/BooleanFilter";
import DateFilter from "./Filter/DateFilter";
import {COMPOSITE_FIELD_TYPE, FIELD_TYPE, RELATION_FIELD_TYPE} from "../model/interface/dataStorage/IField";
import CompositeApprovalFilter from "./Filter/composite/CompositeApprovalFilter";
import CodeFilter from "./Filter/CodeFilter";
import ViewTableItemsSettings from "../components/app/view/table/ViewTableItemsSettings";
import Choose from "./Filter/input/Choose";
import Date from "./Filter/input/Date";
import Range from "./Filter/input/Range";
import Boolean from "./Filter/input/Boolean";
import Search from "./Filter/input/Search";
import Code from "./Filter/input/Code";
import Approval from "./Filter/input/composite/Approval";

interface IProps extends IFilterBaseBuild, IFilterInputBuild {

}

const FilterBuilder: IProps = {
    build(item, field, state, setValue, values, remote = true, allFilters): IFilterFunctions {
        if (!item.filterable || ViewTableItemsSettings.isFilterableDisabled(field)) {
            return {}
        }
        let filter: IFilterFunctions

        switch (field.type) {
            case(RELATION_FIELD_TYPE.MANY_TO_MANY):
            case(RELATION_FIELD_TYPE.ONE_TO_MANY):
            case(RELATION_FIELD_TYPE.MANY_TO_ONE):
            case(RELATION_FIELD_TYPE.ONE_TO_ONE):
                filter = ChooseFilter.build(item, field, state, setValue, values, remote, allFilters)
                break;
            case(FIELD_TYPE.INTEGER):
            case(FIELD_TYPE.FLOAT):
            case(FIELD_TYPE.PRICE):
            case(FIELD_TYPE.COUNT):
                filter = RangeFilter.build(item, field, state, setValue, values)
                break;
            case(FIELD_TYPE.TEXT):
            case(FIELD_TYPE.STRING):
            case(FIELD_TYPE.EMAIL):
            case(FIELD_TYPE.PHONE_NUMBER):
            case(FIELD_TYPE.HTML):
                filter = SearchFilter.build(item, field, state, setValue, values)
                break;
            case(FIELD_TYPE.BOOLEAN):
                filter = BooleanFilter.build(item, field, state, setValue, values)
                break;
            case(FIELD_TYPE.DATE_TIME):
                filter = DateFilter.build(item, field, state, setValue, values)
                break;
            case(FIELD_TYPE.CODE):
                filter = CodeFilter.build(item, field, state, setValue, values)
                break;
            case(COMPOSITE_FIELD_TYPE.APPROVAL):
                filter = CompositeApprovalFilter.build(item, field, state, setValue, values)
                break;
            default:
                throw new Error('Invalid field type: ' + field.type)
        }

        if (remote) {
            delete filter['onFilter'];
        }

        if (state) {
            filter.defaultFilteredValue = state as any
        }

        return filter
    },
    buildInput(item, field, selected, onChange) {
        ViewTableItemsSettings.isFilterableDisabled(field)

        const sharedProps = {item, field, selected, onChange}
        switch (field.type) {
            case(RELATION_FIELD_TYPE.MANY_TO_MANY):
            case(RELATION_FIELD_TYPE.ONE_TO_MANY):
            case(RELATION_FIELD_TYPE.MANY_TO_ONE):
            case(RELATION_FIELD_TYPE.ONE_TO_ONE):
                return <Choose {...sharedProps}/>
            case(FIELD_TYPE.INTEGER):
            case(FIELD_TYPE.FLOAT):
            case(FIELD_TYPE.PRICE):
            case(FIELD_TYPE.COUNT):
                return <Range {...sharedProps}/>
            case(FIELD_TYPE.TEXT):
            case(FIELD_TYPE.STRING):
            case(FIELD_TYPE.EMAIL):
            case(FIELD_TYPE.PHONE_NUMBER):
            case(FIELD_TYPE.HTML):
                return <Search{...sharedProps}/>
            case(FIELD_TYPE.BOOLEAN):
                return <Boolean {...sharedProps}/>
            case(FIELD_TYPE.DATE_TIME):
                return <Date {...sharedProps}/>
            case(FIELD_TYPE.CODE):
                return <Code {...sharedProps}/>
            case(COMPOSITE_FIELD_TYPE.APPROVAL):
                return <Approval {...sharedProps}/>
            default:
                throw new Error('Invalid field type: ' + field.type)
        }
    }
}

export default FilterBuilder