import React from 'react'
import {Link} from "react-router-dom";
import {Button, Col, Descriptions, Modal, Result, Row, Tooltip, Typography} from "antd";
import {default as RouteCard} from "../../../configuration/content-type/card/Card";
import {EditOutlined, MailOutlined, RollbackOutlined, UserOutlined, UserSwitchOutlined} from "@ant-design/icons";
import IEmployee from "../../../../../model/interface/company/IEmployee";
import IBaseProps from "../../../../../model/interface/IBaseProps";
import IconBuilder from "../../../../../utils/IconBuilder";
import MessageList from "../partials/MessageList";
import AvatarWithDefault from "../../../security/AvatarWithDefault";
import IUser from "../../../../../model/interface/security/IUser";
import {connect, RootStateOrAny} from "react-redux";
import {masquerade} from "../../../../../redux/actions/Auth";
import EmployeesService from "../../../../../model/service/company/EmployeesService";
import {CARD_RESERVED_NAMES} from "../../../../../model/interface/dataStorage/ICard";
import UsersService from "../../../../../model/service/security/UsersService";

interface IProps extends IBaseProps {
    resource: IEmployee
    standalone: boolean
    currentUser: IUser
    masquerade: (user: IUser) => void
}

interface IState {
}

class EmployeeProfile extends React.Component<IProps, IState> {
    isCurrentUser() {
        const {resource, currentUser} = this.props
        return resource.user && currentUser.id === resource.user.id
    }

    masquerade(user: IUser) {
        Modal.confirm({
            title: "Opravdu provést?", onOk: () => {
                this.props.masquerade(user)
            }
        })
    }

    getProperties = () => {
        const {resource} = this.props
        return [
            {label: 'Pracovní pozice', value: resource.job?.name},
            {label: 'Jednotka', value: resource.unit?.name},
            {label: 'Společnost', value: resource.company?.name},
            {label: 'Pracovní úvazek', value: resource.obligation?.title},
            {label: 'E-mail', value: resource.email}
        ]
    }

    render() {
        const {resource, standalone, currentUser, history} = this.props

        return (
            <>
                {this.isCurrentUser()
                || UsersService.hasCredential(currentUser, 'employeeCard')
                || (UsersService.hasCredential(currentUser, 'employeeCardSupervisor') && resource.isSubordinate) ?
                    <div>
                        <Row style={{backgroundColor: 'lightgray'}} justify={'start'} align={'bottom'} wrap={false}
                             className={"p-4"}>
                            <Col className={'flex-shrink-0 h-100 align-items-start d-flex align-self-stretch'}>
                                <AvatarWithDefault src={resource.photo ? resource.photo.thumbnailUrl : undefined}
                                                   size={128}
                                                   className={"mr-3"}/>
                            </Col>
                            <div className={'flex-grow-1'}>
                                <Typography.Title
                                    className={"font-size-lg mb-0"}>{resource.fullName}</Typography.Title>
                                <Row justify={"space-between"}>
                                    <Row gutter={[8, 8]} align={'bottom'}>
                                        {resource.user?.username && (
                                            <Col>
                                                <UserOutlined/> {resource.user?.username}
                                            </Col>
                                        )}
                                        {resource.email && (
                                            <Col>
                                                <MailOutlined/> {resource.email}
                                            </Col>
                                        )}
                                        {resource.contacts.map(contact => (
                                            <Tooltip title={contact.type ? contact.type.name : 'Kontakt'}>
                                                <Col>
                                                    {IconBuilder(contact.type ? contact.type.icon : 'QuestionCircleOutlined')} {contact.value}
                                                </Col>
                                            </Tooltip>
                                        ))}
                                    </Row>
                                    <div className={'align-self-baseline'}>
                                        {resource.user && (
                                            <Link to={"/app/security/users/" + resource.user.id}>
                                                <Button type={"primary"} size={"small"} icon={<EditOutlined/>}>
                                                    Upravit zaměstnance
                                                </Button>
                                            </Link>
                                        )}
                                        {UsersService.hasCredential(currentUser, 'masquerade') && resource.user && resource.user.id !== currentUser.id && (
                                            <Tooltip title={"Přihlášením se za uživatele získáte dočasně jeho práva"}>
                                                <Button className={"ml-2"} size={"small"}
                                                        onClick={() => this.masquerade(resource.user!)}
                                                        icon={<UserSwitchOutlined/>}>
                                                    Přihlásit se za
                                                </Button>
                                            </Tooltip>
                                        )}
                                    </div>
                                </Row>

                            </div>
                        </Row>

                        <div className={"p-4"}
                             style={{background: 'white'}}>
                            <Descriptions colon={false}>
                                {this.getProperties().map(property => (
                                    <Descriptions.Item label={<Typography
                                        className={"font-weight-bold"}>{property.label}</Typography>}>
                                        <Typography.Text className={resource.unit ? 'text-muted' : ''}>
                                            {property.value || 'není vyplněno'}
                                        </Typography.Text>
                                    </Descriptions.Item>
                                ))}
                            </Descriptions>
                            {this.buildCard()}
                            {standalone && resource.user && this.isCurrentUser() && (
                                <div className={"mt-3"}>
                                    <MessageList user={resource.user}/>
                                </div>
                            )}
                        </div>
                    </div>
                    :
                    <Result status="404" title="404" subTitle="Nemáte oprávnění prohlížet tento obsah." extra={
                        <Button icon={<RollbackOutlined/>} onClick={() => history.go(-1)} type="primary">
                            Zpět
                        </Button>
                    }/>
                }
            </>
        )
    }

    buildCard() {
        const {history, match, resource} = this.props
        const card = EmployeesService.getInstance().getSlaveContentTypeCard(CARD_RESERVED_NAMES.EMPLOYEE_CARD)
        // TODO select card by permission

        return card ?
            <RouteCard card={card} match={match} history={history} standAlone={false}
                       resource={{...resource, id: resource.virtualEntityId, uuid: resource.virtualEntityUuid}}/> : null
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        currentUser: state.setup.user
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        masquerade: (user: IUser) => dispatch(masquerade(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProfile)