import React from "react";
import IRestServiceFilters from "../../../model/interface/api/IRestServiceFilters";
import ICustomFiltersSettings, {
    ICustomFilterItemSettings
} from "../../../model/interface/dataStorage/view/settings/ICustomFiltersSettings";
import {Col, Dropdown, Menu, Row, Typography} from "antd";
import {connect} from "react-redux";
import selectors from "../../../redux/selectors";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import IconBuilder from "../../../utils/IconBuilder";
import {API_FILTER_TYPE} from "../../../model/constants/ApiConstant";
import Button from "../../shared/button/Button";
import {IAppState} from "../../../redux/store";
import {FilterOutlined} from "@ant-design/icons";

type ActiveCustomFilters = {
    [contentType: string]: {
        [group: string]: { index: number, group: string, contentType: string, filters: IRestServiceFilters } | undefined
    }
}
export type DefaultCustomFilters = { [contentType: string]: IRestServiceFilters | undefined }

interface IState {
    activeFilters: ActiveCustomFilters
}

interface IProps {
    onChange: (filters?: DefaultCustomFilters) => void
    filters?: ICustomFiltersSettings
    findContentTypeByClassName: (name: string) => IContentType
    isMobile: boolean
}

const DEFAULT_GROUP_NAME = '_default'

class ViewCustomFilters extends React.Component<IProps, IState> {


    constructor(props: IProps) {
        super(props)
        this.state = {
            activeFilters: {}
        }
    }

    componentDidMount() {
        const {filters} = this.props
        if (filters) {
            this.setState({activeFilters: ViewCustomFilters.getDefaultActive(filters)})
        }
    }

    static getDefaultActive(filters?: ICustomFiltersSettings) {
        let defaults: ActiveCustomFilters = {}
        if (filters) {
            Object.entries(filters).forEach(([contentType, ctFilters]) => {
                ctFilters.forEach((filter, index) => {
                    const group = typeof filter.group === 'number' ? filter.group.toString() : (filter.group || DEFAULT_GROUP_NAME)
                    if (filter.default) {
                        if (typeof defaults[contentType] === 'undefined') {
                            defaults[contentType] = {}
                        }
                        defaults[contentType][group] = {filters: filter.filters, contentType, group, index}
                    }
                })
            })
        }
        return defaults
    }

    static getDefault(filters?: ICustomFiltersSettings) {
        return this.parseToFilters(this.getDefaultActive(filters))
    }

    onSelectFilter(filters: IRestServiceFilters, index: number, contentType: string, group: string) {
        let activeFilters = this.state.activeFilters
        if (typeof activeFilters[contentType] === 'undefined') {
            activeFilters[contentType] = {}
        }
        if (this.isActive(index, contentType, group)) {
            activeFilters[contentType][group] = undefined
        } else {
            activeFilters[contentType][group] = {
                index,
                contentType,
                group,
                filters
            }
        }
        this.setState({activeFilters}, this.onChange)
    }

    onChange = () => {

        this.props.onChange(ViewCustomFilters.parseToFilters(this.state.activeFilters))
    }

    static parseToFilters(filters: ActiveCustomFilters): DefaultCustomFilters {
        let output = {} as DefaultCustomFilters
        let counter = 0
        Object.entries(filters).forEach(([contentType, contentTypeFilters]) => {
            Object.entries(contentTypeFilters).forEach(([, group]) => {
                if (group) {
                    if (!output[contentType]) {
                        output[contentType] = {};
                    }
                    Object.entries(group.filters).forEach(([, filter]) => {
                        // @ts-ignore
                        let name = typeof filter.field !== 'undefined' ? filter.field : 'filter_' + (counter++)
                        if (typeof output[contentType]![name] !== 'undefined') {
                            if (output[contentType]![name].type === API_FILTER_TYPE["EQUAL"]) {
                                output[contentType]![name].type = API_FILTER_TYPE["CONTAINS"]
                                // @ts-ignore
                                output[contentType]![name].value = [...output[contentType]![name].value, filter.value]
                            } else if (output[contentType]![name].type === API_FILTER_TYPE["CONTAINS"]) {
                                // @ts-ignore
                                output[contentType]![name].value = [...output[contentType]![name].value, filter.value]
                            } else {
                                name = 'filter_' + (counter++)
                                output[contentType]![name] = filter
                            }
                        } else {
                            output[contentType]![name] = filter
                        }
                    })
                }
            })
        })
        return output
        // return Object.fromEntries(Object.entries(filters)
        //     .map(([ct, ctFilters]) => ([ct, ctFilters?.filters])));
    }

    isActive(index: number, contentType: string, group: string) {
        const {activeFilters} = this.state
        if (typeof activeFilters[contentType] !== 'undefined') {
            if (typeof activeFilters[contentType][group] !== 'undefined') {
                return activeFilters[contentType][group]!.index === index
            }
        }
        return false;
    }

    getActiveIndex(contentType: string, group: string) {
        const {activeFilters} = this.state
        if (typeof activeFilters[contentType] !== 'undefined') {
            if (typeof activeFilters[contentType][group] !== 'undefined') {
                return activeFilters[contentType][group]!.index + ''
            }
        }
        return '';
    }

    buildGroups() {
        let output = {} as {
            [contentTypeClass: string]: {
                groups: {
                    [name: string]: { items: (ICustomFilterItemSettings & {index: number})[], name: string}
                }
                contentTypeClass: string
            }
        }
        const {filters} = this.props
        if (filters) {
            Object.entries(filters).forEach(([contentTypeClass, ctFilters]) => {
                if (!output.hasOwnProperty(contentTypeClass)) {
                    output[contentTypeClass] = {
                        groups: {},
                        contentTypeClass
                    }
                }
                ctFilters.forEach((filter, filterIndex) => {
                    const name = typeof filter.group === 'number' ? filter.group.toString() : (filter.group || DEFAULT_GROUP_NAME)
                    const groupIndex = name +  + '_group'
                    if (!output[contentTypeClass].groups.hasOwnProperty(groupIndex)) {
                        output[contentTypeClass].groups[groupIndex] = {
                            items: [],
                            name
                        }
                    }
                    output[contentTypeClass].groups[groupIndex].items.push({...filter, index: filterIndex})
                })
            })
        }
        return output
    }

    render() {
        const {findContentTypeByClassName, isMobile} = this.props
        const groups = this.buildGroups()
        return Object.entries(groups).map(([contentType, contentTypeGroups]) => (
            <div key={contentType}>
                {Object.entries(groups).length > 1 && (
                    <Typography.Title level={4}>
                        {findContentTypeByClassName(contentType).label}
                    </Typography.Title>
                )}
                <Row justify={"start"} gutter={[12, 12]} className={'px-1'}>
                    {Object.entries(contentTypeGroups.groups).map(([_, group]) => (
                        <Col key={group.name}
                             className={(group.items.length > 1 ? "border " : "") + "rounded p-1 mr-2 bg-gray-lightest"}>
                            <Row justify={"start"} gutter={[12, 12]}>
                                {(group.items.length > 3 && isMobile) ? (
                                    <Col>
                                        <Dropdown trigger={["click"]}
                                                  overlay={<Menu
                                                      selectedKeys={[this.getActiveIndex(contentType, group.name)]}>{
                                                      group.items.map(item => {
                                                          return (
                                                              <Menu.Item key={item.index}>
                                                                  <div
                                                                      onClick={e => {
                                                                          e.currentTarget.blur()
                                                                          this.onSelectFilter(item.filters, item.index, contentType, group.name)
                                                                      }}>
                                                                      {item.icon &&
                                                                          <div className={'d-inline-block mr-2'}>
                                                                              {IconBuilder(item.icon)}
                                                                          </div>}
                                                                      {item.label}
                                                                  </div>
                                                              </Menu.Item>
                                                          )
                                                      })}</Menu>}>
                                            <Button size={"small"}
                                                    type={this.isActive(Number(this.getActiveIndex(contentType, group.name)), contentType, group.name) ? "info" : "default"}
                                                    icon={<FilterOutlined/>}>{group.name}</Button>
                                        </Dropdown>
                                    </Col>
                                ) : group.items.map(item => (
                                    <Col key={item.index}>
                                        <Button
                                            size={"small"}
                                            type={this.isActive(item.index, contentType, group.name) ? "info" : "default"}
                                            onClick={() => this.onSelectFilter(item.filters, item.index, contentType, group.name)}
                                            icon={item.icon && IconBuilder(item.icon)}>
                                            {item.label}
                                        </Button>
                                    </Col>
                                ))
                                }
                            </Row>
                        </Col>
                    ))}
                </Row>
            </div>
        ))
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        isMobile: state.theme.isMobile,
        findContentTypeByClassName: (name: string) => selectors.contentTypes.findOneBy(state, 'fullClassName', name)
    }
}

export default connect(mapStateToProps)(ViewCustomFilters)