import React from "react";
import IRestServiceOptions from "../../../../../../../model/interface/api/IRestServiceOptions";
import CompanyStructureService from "../../../../../../../model/service/company/CompanyStructureService";
import EmployeesService from "../../../../../../../model/service/company/EmployeesService";
import ScrollContainer from "../../../../../../shared/scrollContainer/ScrollContainer";
import {Checkbox, Row, Spin} from "antd";
import Utils from "../../../../../../../utils";
import {API_FILTER_TYPE} from "../../../../../../../model/constants/ApiConstant";
import {FieldEmployeeOptionsActiveOn} from "../../../../content-type/field/optionEditors/FieldEmployeeOptionsEditor";
import moment, {Moment} from "moment";
import {DATE_FORMAT_YYYY_MM_DD} from "../../../../../../../model/constants/DateConstant";
import IEmployee from "../../../../../../../model/interface/company/IEmployee";
import IRestServiceFilters from "../../../../../../../model/interface/api/IRestServiceFilters";
import ILabelValue from "../../../../../../../model/interface/util/ILabelValue";

interface IProps {
    order: string
    onSelect: (item: any) => void
    search: string
    maxHeight?: number
    root?: string | string[]
    value?: string | string[]
    onlyDirectChildren?: boolean
    activeOn?: FieldEmployeeOptionsActiveOn
    includeInactive?: boolean
    subordinates?: boolean
    currentEmployee: IEmployee
    presenter?: string
    hideCurrent?: boolean
    filters?: IRestServiceFilters
    onOptionsChange?: (options: ILabelValue<string>[]) => void
}

interface IState {
    data: ILabelValue<string, string>[]
    loading: boolean
    loaded: boolean
}

class EmployeeDisplayList extends React.Component<IProps, IState> {


    constructor(props: IProps) {
        super(props)
        this.state = {
            data: [],
            loading: false,
            loaded: false
        }
    }

    componentDidMount() {
        this.load().then()
    }

    load = () => {
        const {onOptionsChange} = this.props

        return Promise.resolve()
            .then(() => new Promise<void>(resolve => {
                this.setState({data: [], loading: true}, resolve)
            }))
            .then(() => this.fetch())
            .then((data) => new Promise<void>(resolve => {
                this.setState({data, loading: false}, resolve)
                onOptionsChange?.(this.getFilteredData())
            }))
    }

    fetch() {
        const {
            root,
            activeOn,
            onlyDirectChildren,
            includeInactive,
            subordinates,
            currentEmployee,
            filters,
            hideCurrent
        } = this.props

        let params = {
            filters: {...filters}
        } as IRestServiceOptions
        if (onlyDirectChildren || root) {
            if (onlyDirectChildren) {
                if (root) {
                    params.filters![1] = {
                        type: 'equal',
                        field: 'parent',
                        value: root
                    }
                } else {
                    params.filters![1] = {
                        type: 'isNull',
                        field: 'parent'
                    }
                }
                return CompanyStructureService.loadByParentId(root!).then((results) => {
                    return results
                        .filter(node => node.employee && (includeInactive || node.employee.active) && (!hideCurrent || currentEmployee.uuid !== node.employee.uuid))
                        .map(node => ({value: node.employee?.uuid!, label: node.employee?.fullName!}))
                })
            } else if (root) {
                return CompanyStructureService.loadSharedChildrenOnlyChoices(Array.isArray(root) ? root : [root], 'default', {
                    filters: {
                        employee: {
                            field: 'employee',
                            type: API_FILTER_TYPE.IS_NOT_NULL
                        },
                        ...(!includeInactive ? {
                            active: {
                                field: 'employee.active',
                                type: API_FILTER_TYPE.EQUAL, value: true
                            }
                        } : {})
                    }
                }).then(({results}) => {
                    return Object.entries(results)
                        .filter(([key, _]) => (!hideCurrent || currentEmployee.uuid !== key))
                        .map(([value, label]) => ({value, label}))
                })
            }
            return []
        } else {
            params.filters!.parent = {
                type: API_FILTER_TYPE.IS_NULL,
                field: 'parent'
            }
            if (activeOn?.type) {
                let date: Moment
                switch (activeOn.type) {
                    case 'day':
                        date = activeOn.value && activeOn.value > moment().date() ?
                            Utils.previousMonth().date(activeOn.value - 1 || 28)
                            : moment().date(activeOn.value || 28)
                        break;
                    case 'month':
                        date = activeOn.value && activeOn.value > moment().month() + 1 ?
                            moment().month((activeOn.value || 12) - 1).year(moment().year() - 1).endOf('month')
                            : moment().month((activeOn.value || 12) - 1).endOf('month')
                        break;
                    case 'year':
                        date = moment().endOf('year')
                        break;
                }

                params.filters!.activeTo = {
                    type: API_FILTER_TYPE.OR,
                    children: {
                        1: {
                            type: API_FILTER_TYPE.GREATER_OR_EQUAL,
                            field: 'terminationDate',
                            value: date.format(DATE_FORMAT_YYYY_MM_DD)
                        },
                        2: {
                            type: API_FILTER_TYPE.IS_NULL,
                            field: 'terminationDate',
                        }
                    }
                }
                params.filters!.activeFrom = {
                    type: API_FILTER_TYPE.OR,
                    children: {
                        1: {
                            type: API_FILTER_TYPE.LESSER_OR_EQUAL,
                            field: 'beginDate',
                            value: date.format(DATE_FORMAT_YYYY_MM_DD)
                        },
                        2: {
                            type: API_FILTER_TYPE.IS_NULL,
                            field: 'beginDate',
                        }
                    }
                }
            } else if (!includeInactive) {
                params.filters!.active = {
                    type: API_FILTER_TYPE.EQUAL,
                    field: 'active',
                    value: true
                }
            }
            if (hideCurrent && currentEmployee) {
                params.filters!.self = {
                    field: "uuid",
                    type: API_FILTER_TYPE.NOT_EQUAL,
                    value: currentEmployee.uuid
                }
            }

            if (subordinates) {
                return EmployeesService.getInstance().subordinatesChoiceList(this.getPresenter(), currentEmployee, {
                    ...params,
                    allUserEmployees: !hideCurrent
                }).then(({results}) => {
                    return Object.keys(results).map(value => ({value, label: results[value]}))
                })
            }
            return EmployeesService.getInstance().choiceList(this.getPresenter(), params).then(({results}) => {
                return Object.keys(results).map(value => ({value, label: results[value]}))
            })
        }
    }

    getPresenter() {
        if (this.props.presenter) {
            return this.props.presenter
        } else {
            return EmployeesService.getInstance().getDefaultPresenter().name
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        const {onOptionsChange, root, subordinates, search, filters, order} = this.props
        if (JSON.stringify(root) !== JSON.stringify(prevProps.root)
            || subordinates !== prevProps.subordinates
            || JSON.stringify(filters) !== JSON.stringify(prevProps.filters)) {
            this.load().then()
        }

        if (search !== prevProps.search || order !== prevProps.order) {
            onOptionsChange?.(this.getFilteredData())
        }
    }

    getFilteredData() {
        const {search} = this.props
        const orderMark = this.props.order === 'ASC' ? 1 : -1
        // filter unique data
        return this.state.data
            .filter(item => !search || Utils.stringContains(item.label, search))
            .sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? orderMark : -orderMark)
            .filter((item, index, self) => Utils.findIndex(self, {value: item.value}) === index)
    }

    render() {
        const data = this.getFilteredData()
        const {loading} = this.state
        const {value, maxHeight} = this.props

        return (
            <ScrollContainer visibility={"visible"} style={{
                maxHeight: maxHeight || 300,
                overflow: "auto"
            }}>
                {data.length > 0 ? (
                    data.map(item => {
                            const isSelected = (Array.isArray(value) && value?.includes(item.value)) || value === item.value
                            return <Row align={"middle"} key={item.value}
                                        onClick={() => this.props.onSelect(item)}
                                        className={"px-2 py-1 cursor-pointer hover-background" + (isSelected ? ' bg-gray-lighter' : '')}>
                                <Checkbox checked={isSelected} className={'mr-2'}/>{item.label}
                            </Row>
                        }
                    )) : (
                    loading ? <Row justify={"center"} align={"middle"} className={'h-100 p-1'}>
                            <Spin size={"small"} spinning={loading}/>
                        </Row> :
                        <span className={"text-muted"}>
                        {this.props.search.length > 0 ? (<>Pro výraz "{this.props.search}" nejsou žádné
                            výsledky</>) : <>Žádné výsledky</>}
                    </span>
                )}
            </ScrollContainer>
        )
    }
}

export default EmployeeDisplayList