import React from 'react';
import {Button, Col, Input, Row, Tooltip} from "antd";
import {connect, RootStateOrAny} from "react-redux";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import selectors from "../../../redux/selectors";
import IField from "../../../model/interface/dataStorage/IField";
import IRepositoryService from "../../../model/interface/IRepositoryService";
import {API_TOKEN_ENTITY, API_TOKEN_LIST, API_TOKEN_SLAVE_ENTITY} from "../../../model/constants/ApiConstant";
import FormElementField from "../../app/configuration/form/FormElement/FormElementField";
import Utils from "../../../utils";
import {CheckOutlined, SwapOutlined} from "@ant-design/icons";
import IBaseProps from "../../../model/interface/IBaseProps";
import DataStorageService from "../../../model/service/dataStorage/DataStorageService";
import ILabelValue from "../../../model/interface/util/ILabelValue";

export type CustomToken = ILabelValue<string> & {isValid?: (field: IField) => boolean}

interface IProps extends IBaseProps {
    value?: any
    field: IField
    onChange?: (value: any) => void
    className?: string,
    style?: React.CSSProperties,
    disabled?: boolean
    findContentType: (property: string, value: string) => IContentType
    findServiceByClassName: (className: string) => IRepositoryService
    isVirtual: (className: string) => boolean,
    multiple?: boolean
    customTokens?: (ILabelValue<string> & {isValid?: (field: IField) => boolean})[]
}

interface IState {
}

class FieldDefaultValuePicker extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    setValue = (value: any) => {
        this.props.onChange?.(Utils.parseObjectToIdentifier(value, 'uuid'))
    }

    render() {
        const {
            className,
            style,
            disabled,
            value,
            isVirtual,
            match,
            history,
            field,
            findContentType,
            findServiceByClassName,
            multiple,
            customTokens
        } = this.props

        const entity = field?.targetEntity ?
            (isVirtual(field.targetEntity) ? DataStorageService.VIRTUAL_ENTITY : field.targetEntity) : field?.type
        let tokens: ILabelValue<string>[] = entity ? Object.entries(API_TOKEN_LIST)
            .filter(([, t]) => {
                if (t.value === API_TOKEN_ENTITY[entity]){
                    return true
                }
                const service = field.targetEntity ? findServiceByClassName(field.targetEntity) : null
                if (service?.getContentType){
                    const masterClass = service.getContentType()?.masterClass
                    return  masterClass && t.value === API_TOKEN_SLAVE_ENTITY[masterClass]
                }
                return false
            }).map(([, t]) => t) : []

        if (customTokens){
            customTokens.forEach(customToken => {
                if (customToken.isValid ? customToken.isValid(field) : true){
                    tokens?.push({label: customToken.label, value: customToken.value})
                }
            })
        }

        return (
            <Row gutter={[10, 10]} style={style} className={className} align={'middle'} justify={"space-between"}>
                <Col flex={'1 0'}>
                    <FormElementField
                        field={field}
                        functions={{
                            ...{
                                getNode: () => ({field}),
                                getContentType: () => findContentType('uuid', field.contentTypeId!),
                            } as any
                        }}
                        type={'field'}
                        label={''}
                        onChange={this.setValue}
                        match={match}
                        value={tokens?.map(t => t.value).includes(value) ? undefined : value}
                        history={history}
                        id={'default-value-id'}
                        options={{
                            showClear: true,
                            disabled: disabled || (!!tokens && (tokens.map(t => t.value).includes(value)  || (tokens.map(t => t.value).includes('content-type') && typeof value === "string" && !Utils.isUuid(value)))),
                            multiple: multiple,
                            companyStructureMultiple: multiple,
                            employeeMultiple: multiple
                        }}
                        noName={true}
                        noStyle={true}
                        preview={true}
                    />
                </Col>
                {tokens?.length > 0 && (
                    <>
                        <Col className={'text-center'}>
                            <SwapOutlined/>
                        </Col>
                        <Col>
                            <Tooltip
                                title={'vždy aktuální hodnota v závislosti na přihlášeném uživateli'}>
                                <Input.Group compact={true}>
                                    {tokens?.map(token => (
                                        <Button
                                            disabled={disabled}
                                            icon={value === token.value &&
                                                <CheckOutlined/>}
                                            onClick={() => this.setValue(value === token.value ? undefined : token?.value)}
                                            type={value === token.value ?
                                                'primary' : 'default'
                                            }>
                                            {token.label}
                                        </Button>                                    ))}
                                </Input.Group>
                            </Tooltip>
                        </Col>
                    </>
                )}
            </Row>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {

    return {
        findContentType: (property: string, value: string) => selectors.contentTypes.findOneBy(state, property, value),
        findServiceByClassName: (className: string) => selectors.services.findOneByFullClassName(state, className),
        isVirtual: (className: string) => selectors.services.isVirtual(className),
    }
}

export default connect(mapStateToProps)(FieldDefaultValuePicker)
