import React from "react";
import {Button as ButtonAntd, ButtonProps} from "antd";
import {ButtonType} from "antd/lib/button/button";
import IState from "../../../model/interface/dataStorage/workflow/IState";
import {ButtonSize} from "antd/es/button";

export type ButtonTypes = ButtonType | 'info' | 'success' | 'warning' | 'special' | 'danger' | 'orange'

export type ButtonSizes = ButtonSize | 'extraSmall'


export interface IButtonProps extends Omit<ButtonProps, 'type' | 'size'> {
    type?: ButtonTypes
    size?: ButtonSizes
    inverse?: boolean
}

class Button extends React.Component<IButtonProps, IState> {

    getClass(type: ButtonTypes) {
        switch (type) {
            case "info":
                return 'ant-btn-info'
            case "warning":
                return 'ant-btn-warning'
            case "success":
                return 'ant-btn-success'
            case "special":
                return 'ant-btn-special'
        }
    }

    render() {
        let {type, inverse, className, size} = this.props
        if (type && ['info', 'success', 'warning', 'special'].includes(type)) {
            className = this.getClass(type) + ' ' + (className || '')
            type = 'default'
        }
        if(inverse) {
            className += ' btn-inverse'
        }

        if (size === 'extraSmall'){
            className += ' ant-btn-xs'

        }

        const finalSize: ButtonSize = size === 'extraSmall' ? undefined : size


        return (
            <ButtonAntd {...this.props} size={finalSize} className={className}
                        type={type as ButtonType}/>
        )
    }
}

export default Button