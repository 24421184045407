import React, {RefObject} from 'react'
import {Link, Prompt} from 'react-router-dom'
import ContentTypesService from "model/service/dataStorage/ContentTypesService"
import {Badge, Breadcrumb, Button, Card, FormInstance, Menu, message, Select, Spin, Tooltip} from 'antd';
import {
    AppstoreAddOutlined,
    BarChartOutlined,
    BuildOutlined, CodeOutlined,
    ControlOutlined,
    EyeOutlined,
    HomeOutlined,
    InfoCircleOutlined,
    KeyOutlined,
    MessageOutlined,
    ProfileOutlined,
    RollbackOutlined,
    SaveOutlined,
    SearchOutlined,
    SettingOutlined,
    ShareAltOutlined,
    ThunderboltOutlined,
    UnorderedListOutlined
} from "@ant-design/icons";
import Flex from "old/shared-components/Flex";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import IExtensionType from "../../../../model/interface/dataStorage/IExtensionType";
import ContentTypeProperties from "./ContentTypeProperties";
import ContentTypeFields from "./ContentTypeFields";
import ContentTypeActions from "./ContentTypeActions";
import ContentTypePresenters from "./ContentTypePresenters";
import ContentTypePermissions from "./ContentTypePermissions";
import ContentTypeScripts from "./ContentTypeScripts";
import IBaseProps from "../../../../model/interface/IBaseProps";
import ErrorReport from "../../error/ErrorReport";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../../redux/reducers/Setup";
import IFieldType from "../../../../model/interface/dataStorage/IFieldType";
import type {MenuInfo} from 'rc-menu/es/interface';
import IExtension from "../../../../model/interface/dataStorage/IExtension";
import _ from "underscore"
import IField from "../../../../model/interface/dataStorage/IField";
import Utils from "../../../../utils";
import ContentTypeCards from "./ContentTypeCards";
import ContentTypeForms from "./ContentTypeForms";
import {reLoad} from "../../../../redux/actions/Setup";
import IForm from "../../../../model/interface/form/IForm";
import ICardWidget from "../../../../model/interface/dataStorage/card/ICardWidget";
import IRestServiceOptions from "../../../../model/interface/api/IRestServiceOptions";
import IScreen from "../../../../model/interface/ui/IScreen";
import ContentTypeRoutes from "./ContentTypeRoutes";
import IContentTypeRoute from "../../../../model/interface/dataStorage/IContentTypeRoute";
import IWidget from "../../../../model/interface/form/IWidget";
import ContentTypeMaintenance from "./ContentTypeMaintenace";
import ContentTypeNotifications from "./ContentTypeNotifications";
import FormFieldType from "../form/FormElement/formField/FormFieldType";
import FieldEditor from "../form/FormElement/optionEditor/FieldEditor";
import ContentTypeViews from "./ContentTypeViews";
import ContentTypeStatistics from "./ContentTypeStatistics";
import IView from "../../../../model/interface/dataStorage/IView";
import ICard from "../../../../model/interface/dataStorage/ICard";
import ContentTypeConfiguration from "./ContentTypeConfiguration";
import IAction from "../../../../model/interface/dataStorage/IAction";
import selectors from "../../../../redux/selectors";
import IRepositoryService from "../../../../model/interface/IRepositoryService";
import ICompositeFieldType from "../../../../model/interface/dataStorage/field/ICompositeFieldType";

export interface IContentTypeStepProps extends IBaseProps {
    resource: IContentType,
    extensionTypes: IExtensionType[],
    onValuesChange: (values: { [x: string]: any }) => void,
    fieldScalarTypes: IFieldType[],
    compositeFieldTypes: ICompositeFieldType[]
    relationTypes: { value: string, label: string }[],
    contentTypes: IContentType[]
    onStepChange: (step: string) => void
    forms: IForm[]
}

interface IProps extends IBaseProps {
    contentTypes: IContentType[],
    fieldTypes: IFieldType[],
    compositeFieldTypes: ICompositeFieldType[]
    extensionTypes: IExtensionType[],
    forms: IForm[],
    masterClasses: string[],
    reLoad: () => void,
    loaded: boolean
    resource?: IContentType,
    standAlone?: boolean,
    preview?: boolean
    views: IView[]
    findServiceByClassName: (name: string) => IRepositoryService | null
}

interface IState {
    resource: IContentType,
    step: string,
    shouldBlockNavigation: boolean,
    loading: boolean,
    extensionTypes: IExtensionType[],
    fieldScalarTypes: { value: string, label: string }[],
    relationTypes: { value: string, label: string }[],
    contentTypes: IContentType[],
    propertiesFormRef: RefObject<FormInstance>
    screen: IScreen
    forms: IForm[]
    screenTimeout: null | NodeJS.Timeout
}

class ContentType extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        const resourceUuid = Utils.uuid()
        this.state = {
            resource: {
                id: 0,
                uuid: resourceUuid,
                name: '',
                label: '',
                description: '',
                fullClassName: '',
                collection: '',
                revisionHistory: true,
                actions: [
                    {
                        uuid: Utils.uuid(),
                        name: 'show',
                        type: 'show',
                        label: 'Zobrazit',
                        form: null,
                        forms: [],
                        locked: true,
                        contentType: resourceUuid,
                        notifications: [],
                        actionScripts: []
                    }
                ],
                fields: [],
                presenters: [],
                notifications: [],
                extensions: [],
                rules: [],
                masterClass: '',
                cards: [],
                forms: [],
                routes: [],
                readAction: null,
                parent: null,
                scripts: [],
                views: []
            } as IContentType,
            step: 'basic',
            shouldBlockNavigation: false,
            loading: false,
            forms: this.props.forms,
            extensionTypes: [],
            fieldScalarTypes: [],
            relationTypes: [],
            contentTypes: [],
            propertiesFormRef: React.createRef() as RefObject<FormInstance>,
            screen: Utils.getScreenInfo(),
            screenTimeout: null
        }
    }

    componentDidMount() {
        const {resource} = this.props
        if (resource) {
            return this.setResource(resource)
        }
        this.load()
    }

    componentDidUpdate = () => {
        if (this.state.shouldBlockNavigation) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = null
        }
    }

    load() {
        const {match} = this.props
        this.setState({loading: true})
        Promise.all([
            'contentTypeId' in match.params && match.params['contentTypeId'] ? ContentTypesService.resourceRetrieve(
                parseInt(match.params['contentTypeId'], 10)
            ).then(resource => {
                this.setResource(resource)
            }) : null,
            ContentTypesService.getFieldRelationTypes().then(relationTypes => {
                this.setState({
                    relationTypes
                })
            })
        ]).then(() => {
            this.setState({loading: false})
        })

        Utils.registerScreenUpdate(
            (screenTimeout: NodeJS.Timeout) => this.setState({screenTimeout}),
            (screen: IScreen) => this.setState({screen}),
        )
    }

    setResource(resource: IContentType) {
        const {forms} = this.props
        this.setState({
            resource: {
                ...resource,
                forms: forms
                    .filter(f => f.uuid && (resource.forms as unknown as string[] /*TODO ?*/).includes(f.uuid))
            }
        }, this.state.propertiesFormRef.current?.resetFields)
    }

    handleClick = ({key}: MenuInfo) => {
        this.setState({
            step: key as string
        })
    }

    handleChangeGroup(step: string) {
        this.setState({step})
    }

    onValuesChange = (values: { [x: string]: string }) => {
        let resource = {
            ...this.state.resource,
            ...values
        }
        if (typeof values['extensions'] !== 'undefined') {
            resource = this.checkExtensionFields(resource)
        }

        this.setState(_state => ({
            resource
        }))
        this.forceUpdate()
    }

    checkExtensionFields = (resource: IContentType) => {
        resource.extensions.forEach((extension: IExtension) => {
            const extensionType = _.findWhere(this.props.extensionTypes, {type: extension.type})
            if (!extensionType) {
                throw new Error('Invalid extension type: ' + extension.type)
            }
            extensionType.fields.forEach((extensionField) => {
                const {name} = extensionField
                const index = Utils.findIndex(resource.fields, {name})
                const resourceField: IField = resource.fields[index]
                if (resourceField) {
                    resource.fields[index].locked = true
                } else {
                    const field: IField = {
                        ...extensionField,
                        locked: true
                    }
                    resource.fields.push(field)
                }
            })
        })
        return resource
    }

    onSave = (stay: boolean) => {
        this.state.propertiesFormRef.current?.validateFields()
            .then(() => stay ? this.saveAndStay() : this.saveAndBack())
            .catch(() => {
                this.setState({step: 'basic'})
            })
    }

    save(): Promise<IContentType | void> {
        const {resource} = this.state
        const id = this.state.resource.id
        const data = {
            ...resource,
            masterClass: resource.masterClass ?? null,
            actions: resource.actions.map(action => ContentTypeConfiguration.mapAction(action)),
            fields: resource.fields.map(field => ({
                ...field,
                label: field.label ? field.label : null,
                contentTypeName: field.contentTypeName || false,
                script: field.script || null,
                loadInCollection: !!field.loadInCollection,
                loadInResource: !!field.loadInResource,
                defaultValue: FormFieldType.formatFromForm(FieldEditor.detectType(field), field.defaultValue) || null,
                deleteRelated: !!field.deleteRelated
            })),
            presenters: resource.presenters,
            extensions: resource.extensions.map(extension => ({
                id: extension.id,
                type: extension.type
            })),
            rules: resource.rules.length > 0 ? resource.rules.map((rule, index) => ({
                ...rule,
                conditions: rule.conditions ? rule.conditions.map(condition => ({
                    ...condition,
                    field: condition.field || null,
                    inheritRule: condition.inheritRule || null,
                })) : null,
                ruleConnections: rule.ruleConnections, // TODO ?
                weight: index + 1
            })) : null,
            cards: ContentTypeConfiguration.prepareCards(resource.cards),
            forms: ContentTypeConfiguration.prepareForms(resource.forms),
            notifications: resource.notifications || [],
            routes: resource.routes.length > 0 ? resource.routes : resource._createBasicRoutes
            && resource._basicRouteName ? this.buildBasicRoutes(resource._basicRouteName) : [],
            scripts: resource.scripts || []
        }
        this.setState({
            loading: true
        })
        const options = {depth: 2} as IRestServiceOptions
        const promise = id ? ContentTypesService.resourceUpdate(id, data, options) : ContentTypesService.collectionCreate(data, options)
        return promise.then(contentType => {
            message.success(
                `Typ obsahu [${contentType.label}] byl úspěšně uložen.`
            ).then()
            this.props.reLoad()
            return contentType
        }).catch(error => {
            ErrorReport.create("Při ukládání typu obsahu vznikla chyba", error, data)
            this.setState({loading: false})
        })
    }

    static prepareForms(forms: IForm[] = []): IForm[] {
        return forms.length > 0 ? forms.map(form => ({
            id: form.id ? form.id : undefined,
            name: form.name,
            label: form.label,
            priority: form.priority,
            contentType: form.contentType,
            conditions: form.conditions,
            widgets: form.widgets.map((widget: IWidget) => {
                const item = {...widget} as any
                item.children = undefined
                return item
            })
        })) : []
    }

    static mapAction(action: IAction) {
        return {
            id: action.id || null,
            uuid: action.uuid,
            name: action.name,
            label: action.label || '',
            locked: action.locked,
            type: action.type,
            forms: action.forms ? action.forms : null,
            actionScripts: action.actionScripts ? action.actionScripts.map(actionScript => ({
                ...actionScript,
                script: actionScript.script || null,
                action: undefined,
            })) : null,
            route: action.route || null,
            afterRedirect: action.afterRedirect || null,
            confirmModalEnabled: action.confirmModalEnabled || false,
            confirmModalText: action.confirmModalText || null,
            modal: action.modal || false,
            relationAction: action.relationAction,
            importSource: action.importSource || null,
            generatedDocumentPattern: action.generatedDocumentPattern || null,
            contentType: action.contentType
        };
    }

    buildBasicRoutes(routePath: string): IContentTypeRoute[] | unknown {
        return [
            {name: 'list', route: {url: '/app/' + routePath}},
            {name: 'show', route: {url: '/app/' + routePath + '/:identifier'}},
            {name: 'edit', route: {url: '/app/' + routePath + '/:identifier/upravit'}},
            {name: 'create', route: {url: '/app/' + routePath + '/vytvorit'}}
        ]
    }

    static prepareCards = (cards: ICard[] = []) => {
        return cards.length > 0 ? cards.map(card => ({
            id: card.id ? card.id : null,
            name: card.name,
            label: card.label,
            route: card.route,
            widgets: card.widgets.map((widget: ICardWidget) => {
                const item = {...widget} as any
                item.children = undefined
                return item
            }),
            routePriority: card.routePriority,
            action: card.action,
            conditions: card.conditions
        })) : []
    }

    saveAndStay() {
        this.save().then(contentType => {
            if (!contentType) {
                return
            }
            this.props.history.push('/app/configuration/content-types/' + contentType.id)
        })
    }

    saveAndBack() {
        this.save().then(contentType => {
            if (!contentType) {
                // error was thrown
                return
            }
            this.props.history.go(-1)
        })
    }

    onStepChange = (step: string) => {
        this.setState({step})
    }

    buildGroups() {
        return [
            {
                "name": "basic",
                "icon": (<InfoCircleOutlined/>),
                "title": "Základní informace"
            },
            {
                "name": "fields",
                "icon": (<UnorderedListOutlined/>),
                "title": "Pole a vazby",
                "count": this.state.resource.fields.length
            },
            {
                "name": "forms",
                "icon": (<ProfileOutlined/>),
                "title": "Formuláře",
                "count": this.state.resource.forms.length
            },
            {
                "name": "actions",
                "icon": (<ThunderboltOutlined/>),
                "title": "Akce",
                "count": this.state.resource.actions.length
            },
            {
                "name": "presenters",
                "icon": (<SearchOutlined/>),
                "title": "Zobrazení",
                "count": this.state.resource.presenters.length
            },
            {
                "name": "cards",
                "icon": (<AppstoreAddOutlined/>),
                "title": "Karty",
                "count": this.state.resource.cards.length
            },
            {
                "name": "permissions",
                "icon": (<KeyOutlined/>),
                "title": "Práva",
                "count": this.state.resource.rules.length
            },
            {
                name: 'routes',
                icon: <ShareAltOutlined/>,
                title: 'URL cesty',
                "count": this.state.resource.routes.length
            },
            {
                name: 'notifications',
                icon: <MessageOutlined/>,
                title: 'Upozornění',
                "count": this.state.resource.notifications.length
            },
            {
                name: 'views',
                icon: <EyeOutlined/>,
                title: 'Pohledy',
                "count": this.listViews().length
            },
            {
                name: 'scripts',
                icon: <CodeOutlined/>,
                title: 'Scripty',
                "count": this.state.resource.scripts.length
            }
        ]
    }


    protected listViews(): IView[] {
        if (!this.state.resource) {
            return []
        }
        return this.props.views
            .filter(view => {
                return view.contentTypes.indexOf(this.state.resource!.uuid) >= 0
            })
            .sort((a, b) => {
                return a.label > b.label ? 1 : -1
            })
    }

    render() {
        const {
            step,
            resource,
            shouldBlockNavigation,
            loading,
            relationTypes,
            propertiesFormRef,
            forms,
            screen
        } = this.state
        const {
            contentTypes,
            fieldTypes,
            compositeFieldTypes,
            extensionTypes,
            masterClasses,
            history,
            match,
            standAlone,
            preview,
            loaded,
            findServiceByClassName
        } = this.props
        const {label} = resource
        const stepProps = {
            forms,
            onValuesChange: this.onValuesChange,
            extensionTypes: extensionTypes,
            resource: {
                ...resource,
                fields: resource.fields.filter(f => !f.targetEntity || findServiceByClassName(f.targetEntity))
            },
            fieldScalarTypes: fieldTypes,
            compositeFieldTypes,
            relationTypes,
            contentTypes,
            history,
            match,
            onStepChange: this.onStepChange
        }

        const content = (
            <>
                <div>
                    <Flex>
                        <div className={"pl-3"}>
                            <h1>{label}</h1>
                        </div>
                    </Flex>
                </div>
                <div className={screen.isMobile ? "" : "inner-app-layout"}>
                    {screen.isMobile ? (
                        <Select
                            className={"w-100 mb-4"}
                            defaultValue={"basic"}
                            onChange={(value: string) => this.handleChangeGroup(value)}
                            options={this.buildGroups().map(group => ({
                                value: group.name,
                                label: (<>{group.icon} {group.title}</>)
                            }))}
                        />
                    ) : (
                        <div className={"side-content d-block"}>
                            <Menu
                                onClick={this.handleClick}
                                mode="vertical"
                                defaultSelectedKeys={['basic']}
                            >
                                <Menu.ItemGroup key="g1" title="Konfigurace">
                                    {this.buildGroups().map((group, index) => (
                                        <Menu.Item key={group.name} active={index === 0}>
                                            {group.icon}
                                            {group.count === undefined
                                                ? (<span>{group.title}</span>)
                                                : (<span>{group.title} <Badge count={group.count}
                                                                              className={"float-right"} style={{
                                                    transform: 'translateY(45%)',
                                                    verticalAlign: 'middle',
                                                    backgroundColor: '#fff',
                                                    color: '#999',
                                                    boxShadow: '0 0 0 1px #d9d9d9 inset'
                                                }} showZero={true}/></span>)
                                            }

                                        </Menu.Item>
                                    ))}
                                </Menu.ItemGroup>
                                {!preview && (
                                    <Menu.ItemGroup key="g2" title="Údržba">
                                        <Menu.Item key={"maintenance"}>
                                            <ControlOutlined/>
                                            <span>Správa</span>
                                        </Menu.Item>
                                        <Menu.Item key={"statistics"}>
                                            <BarChartOutlined/>
                                            <span>Statistiky</span>
                                        </Menu.Item>
                                    </Menu.ItemGroup>
                                )}
                            </Menu>
                            {!preview && (
                                <Button.Group>
                                    <Button className={"mt-3"} type={"primary"} icon={<SaveOutlined/>}
                                            onClick={() => this.onSave(true)}>
                                        Uložit
                                    </Button>
                                    <Tooltip title={'Uložit a odejít'}>
                                        <Button className={"mt-3"} type={"primary"} icon={<RollbackOutlined/>}
                                                onClick={() => this.onSave(false)}/>
                                    </Tooltip>
                                </Button.Group>
                            )}
                        </div>
                    )}

                    <div className={"main-content gutter overflow-hidden"}>
                        <ContentTypeProperties
                            {...stepProps}
                            formRef={propertiesFormRef}
                            hidden={step !== 'basic'}
                            masterClasses={masterClasses}
                        />
                        {(
                            {
                                'fields': <ContentTypeFields {...stepProps} resource={resource}/>,
                                'actions': <ContentTypeActions {...stepProps} />,
                                'presenters': <ContentTypePresenters {...stepProps} />,
                                'cards': <ContentTypeCards {...stepProps} />,
                                'forms': <ContentTypeForms {...stepProps} />,
                                'permissions': <ContentTypePermissions {...stepProps} />,
                                'scripts': <ContentTypeScripts {...stepProps} />,
                                'routes': <ContentTypeRoutes {...stepProps} />,
                                'notifications': <ContentTypeNotifications {...stepProps} />,
                                'views': <ContentTypeViews views={this.listViews()} {...stepProps} />,
                                'maintenance': <ContentTypeMaintenance setupLoaded={loaded}
                                                                       setupReLoad={reLoad} {...stepProps}/>,
                                'statistics': <ContentTypeStatistics {...stepProps}/>,
                            }[step]
                        )}
                    </div>

                    {screen.isMobile && !preview && (
                        <div className={"mt-3"}>
                            <Button.Group>
                                <Button className={"mt-3"} type={"primary"} icon={<SaveOutlined/>}
                                        onClick={() => this.onSave(true)}>
                                    Uložit
                                </Button>
                                <Tooltip title={'Uložit a odejít'}>
                                    <Button className={"mt-3"} type={"primary"} icon={<RollbackOutlined/>}
                                            onClick={() => this.onSave(false)}/>
                                </Tooltip>
                            </Button.Group>
                        </div>
                    )}
                </div>
            </>
        )

        return standAlone ? content : (
            <>
                <Breadcrumb className={"mb-3"}>
                    <Breadcrumb.Item href={"/app"}>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={"/app/configuration"}>
                            <SettingOutlined/>
                            <span>Konfigurace</span>
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {loading ? (
                            <Spin spinning={true}/>
                        ) : (
                            <>
                                <BuildOutlined/>
                                <span>{resource.id ? resource.name : 'Nový typ obsahu'}</span>
                            </>
                        )}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Prompt
                    when={shouldBlockNavigation}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                <Card loading={loading}>
                    {content}
                </Card>
            </>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {
        loaded,
        contentTypes,
        fieldTypes,
        extensionTypes,
        masterClasses,
        forms,
        views,
        compositeFieldTypes
    } = state.setup as ISetupState

    return {
        loaded,
        contentTypes,
        fieldTypes,
        compositeFieldTypes,
        extensionTypes,
        masterClasses,
        forms,
        views,
        findServiceByClassName: (name: string) => selectors.services.findOneOrNullByFullClassName(state, name)
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        reLoad: () => dispatch(reLoad())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentType)

