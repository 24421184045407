import {Divider, Modal, notification, Row, Slider, Tooltip} from "antd";
import {
    ColumnHeightOutlined,
    FormatPainterOutlined,
    LineHeightOutlined,
    SaveOutlined,
    TableOutlined
} from "@ant-design/icons";
import React from "react";
import {connect, RootStateOrAny} from "react-redux";
import IUser from "../../model/interface/security/IUser";
import Button from "../shared/button/Button";
import {onFontSizeChange, onMarginChange, onTableSizeChange} from "../../redux/actions/Theme";
import UsersService from "../../model/service/security/UsersService";
import {ITheme} from "../../model/interface/security/ITheme";

interface IProps {
    user: IUser
    margin: string
    fontSize: string
    tableSize: string
    onMarginChange: (margin: string) => void
    onFontSizeChange: (fontSize: string) => void
    onTableSizeChange: (fontSize: string) => void
    className: string
    toolTip?: boolean
    title?: boolean
}

interface IState {
    loading: boolean
    oldValues: ITheme | null
}

const MODAL_KEY = 'nav-theme-modal'

class NavConfiguration extends React.Component<IProps, IState> {


    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            loading: false,
            oldValues: null as null | ITheme
        }
    }

    static defaultProps = {
        title: true
    }

    componentWillUnmount() {
        notification.close(MODAL_KEY)
    }

    changeMargin(margin: string) {
        this.props.onMarginChange(margin)
    }

    changeFontSize(fontSize: string) {
        this.props.onFontSizeChange(fontSize)
    }

    changeTableSize(tableSize: string) {
        this.props.onTableSizeChange(tableSize)
    }

    save(): Promise<void> {
        const {fontSize, margin, tableSize, user} = this.props
        this.setState({loading: true}, this.onOpen)
        return UsersService.resourceUpdate(user.id!, {theme: {fontSize, margin, tableSize}}).then(
            () => new Promise<void>(resolve => {
                notification.close(MODAL_KEY)
                this.setState({loading: false, oldValues: null}, resolve)
            }))
    }

    close() {
        const {oldValues} = this.state
        const {margin, fontSize, tableSize} = this.props
        if (oldValues && (
            oldValues!.margin !== margin
            || oldValues!.fontSize !== fontSize
            || oldValues!.tableSize !== tableSize)) {
            Modal.confirm({
                content: 'Uložit změny?',
                onOk: () => this.save(),
                onCancel: () => {
                    this.props.onMarginChange(oldValues.margin)
                    this.props.onFontSizeChange(oldValues.fontSize)
                    this.props.onTableSizeChange(oldValues.tableSize)
                    notification.close(MODAL_KEY)
                }
            })
        } else {
            notification.close(MODAL_KEY)
        }
    }

    onOpen() {
        const {margin, fontSize, tableSize} = this.props
        const {loading} = this.state
        notification.open({
            icon: false,
            style: {width: 300},
            message: 'Přizpůsobit vzhled',
            key: MODAL_KEY,
            duration: 0,
            description: <div className="p-2">
                <div>
                    <Divider><TableOutlined/> Rozložení tabulky</Divider>
                    <Slider min={1} max={4} step={1}
                            defaultValue={this.sizeToNumber(tableSize)}
                            onChange={(value: number) => this.changeTableSize(this.numberToSize(value))}
                    />
                </div>

                <div>
                    <Divider><ColumnHeightOutlined/> Mezery</Divider>

                    <Slider min={1} max={4} step={1}
                            defaultValue={this.sizeToNumber(margin)}
                            onChange={(value: number) => this.changeMargin(this.numberToSize(value))}
                    />
                </div>

                <div>
                    <Divider><LineHeightOutlined/> Velikost písma</Divider>

                    <Slider step={1} min={1} max={5}
                            defaultValue={this.sizeToNumber(fontSize)}
                            onChange={(value: number) => this.changeFontSize(this.numberToSize(value))}
                    />
                </div>

                <Row justify={"space-between"}>
                    <Button loading={loading} icon={<SaveOutlined/>} type={"primary"} onClick={() => this.save()}
                            className={'mr-2'}>
                        Uložit
                    </Button>
                    <Button type={"default"} onClick={() => this.close()}>Zavřít</Button>
                </Row>
            </div>,
            onClose: this.close
        })
        this.setState({oldValues: {margin, fontSize, tableSize}})
    }

    numberToSize(value: number): string {
        switch (value) {
            case(1):
                return 'xs';
            case(2):
                return 'sm';
            default:
            case(3):
                return 'md';
            case(4):
                return 'lg';
            case(5):
                return 'xl';
        }
    }

    sizeToNumber(size: string): number {
        switch (size) {
            case('xs'):
                return 1;
            case('sm'):
                return 2;
            default:
            case('md'):
                return 3;
            case('lg'):
                return 4;
            case('xl'):
                return 5;
        }
    }

    render() {
        const {toolTip, title, className} = this.props
        return (
            <Tooltip title={toolTip ? "Přizpůsobit vzhled" : ''} placement={"bottom"}>
                <div onClick={() => this.onOpen()} className={className}>
                    <FormatPainterOutlined className="nav-icon mx-auto mr-3"/>
                    {title && <span>{"Přizpůsobit vzhled"}</span>}
                </div>
            </Tooltip>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {margin, fontSize, tableSize} = state.theme
    const {user} = state.setup
    return {
        margin, fontSize, tableSize, user
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        onMarginChange: (margin: string) => dispatch(onMarginChange(margin)),
        onFontSizeChange: (fontSize: string) => dispatch(onFontSizeChange(fontSize)),
        onTableSizeChange: (tableSize: string) => dispatch(onTableSizeChange(tableSize)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavConfiguration)
