import React from "react";
import {Dropdown, Menu, Row, Spin} from "antd";
import {connect} from 'react-redux'
import selectors from "../../redux/selectors";
import IMenu from "../../model/interface/ui/IMenu";
import MenuGroupBuilder from "../app/configuration/menu/builder/MenuGroupBuilder";
import ScrollContainer from "../shared/scrollContainer/ScrollContainer";
import IRoute from "../../model/interface/dataStorage/IRoute";
import {Link, match, RouteComponentProps, withRouter} from "react-router-dom";
import {History} from "history";
import IBookmark from "../../model/interface/ui/IBookmark";
import Bookmark from "../app/bookmark/Bookmark";
import IconBuilder from "../../utils/IconBuilder";
import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    ExportOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UpOutlined
} from "@ant-design/icons";
import BookmarkModal from "../app/bookmark/BookmarkModal";
import {EllipsisVertical} from "react-ionicons";
import {bookmarksUpdate} from "../../redux/actions/Setup";
import BookmarksService from "../../model/service/ui/BookmarksService";
import _ from "underscore";
import SettingsService from "../../model/service/SettingsService";
import Logo from "./Logo";
import {IAppState} from "../../redux/store";
import ISettings from "../../model/interface/ui/ISettings";
import {onMobileNavToggle, toggleCollapsedNav} from "../../redux/actions/Theme";

// const closeMobileNav = () => {
//     // if (!utils.getBreakPoint(useBreakpoint()).includes('lg')) {
//     //     onMobileNavToggle(false)
//     // }
// }

interface IProps extends RouteComponentProps {
    findOneByUrl: (path: string) => IRoute
    findOneByKey: (key: string) => IMenu
    hideGroupTitle: boolean
    _match: match
    _history: History
    isMobile: boolean
    bookmarks: IBookmark[]
    bookmarksUpdate: (bookmarks: IBookmark[]) => any
    toggleCollapsedNav?: (toggle: boolean) => void,
    navCollapsed: boolean
    settings: ISettings
    onMobileNavToggle: (toggle: boolean) => void,
}

interface IState {
    loading: boolean
    loadingBookmarks: boolean
    bookmark: IBookmark | undefined,
    opened: string[],
    selected?: string[]
}

const LAST_OPENED_GROUPS = 'last_opened_menu_groups'

const MAIN_MENU = 'main-menu'

class MenuContent extends React.Component<IProps, IState> {


    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            loading: false,
            loadingBookmarks: false,
            bookmark: undefined,
            opened: [],
            selected: []
        }
    }


    getLastOpenedGroups() {
        const lastOpened = localStorage.getItem(LAST_OPENED_GROUPS)
        return lastOpened ? JSON.parse(lastOpened) : []
    }

    onClick = () => {

    }

    onClickBookmark = () => {
        const {onMobileNavToggle, isMobile} = this.props

        isMobile && onMobileNavToggle(false)
    }

    componentDidMount() {
        this.openAndSelect()
    }

    setOpened = (keys: string[]) => {
        this.setState({opened: keys}, () => this.setLastOpenedGroups(keys))
    }

    setLastOpenedGroups = (keys: string[]) => {
        localStorage.setItem(LAST_OPENED_GROUPS, JSON.stringify(keys))
    }

    editBookmark(bookmark: IBookmark) {
        this.setState({bookmark})
    }

    moveBookmark(bookmark: IBookmark, change: number) {
        let bookmarks = this.props.bookmarks
        let update = [] as IBookmark[]
        let index = bookmarks.findIndex(_bookmark => _bookmark.uuid === bookmark.uuid)
        if (index < 0) {
            throw new Error('Bookmark index not found')
        }
        if (change > 0 && index < bookmarks.length - 1) {
            bookmarks[index] = bookmarks[index + 1]
            bookmarks[index + 1] = bookmark
            update = [bookmarks[index], bookmarks[index + 1]]
        }
        if (change < 0 && index > 0) {
            bookmarks[index] = bookmarks[index - 1]
            bookmarks[index - 1] = bookmark
            update = [bookmarks[index], bookmarks[index - 1]]
        }
        let weight = 0
        bookmarks.forEach(bookmark => {
            bookmark.weight = weight++
        })
        this.props.bookmarksUpdate(bookmarks)
        this.setState({loading: true})
        let promises = [] as Promise<any>[]
        update.forEach(bookmark => {
            promises.push(BookmarksService.resourceUpdate(bookmark.uuid, {weight: bookmark.weight}))
        })
        Promise.all(promises).then(() => this.setState({loading: false}))
    }

    removeBookmark(bookmark: IBookmark) {
        console.log('remove bookmark', bookmark)
        let bookmarks = this.props.bookmarks
        let index = bookmarks.findIndex(_bookmark => _bookmark.uuid === bookmark.uuid)
        if (index < 0) {
            throw new Error('Bookmark index not found')
        }
        bookmarks.splice(index)
        this.props.bookmarksUpdate(bookmarks)
        BookmarksService.resourceDelete(bookmark.uuid).then()
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const {location} = this.props
        if (prevProps.location.pathname !== location.pathname) {
            this.openAndSelect();
        }
    }

    openAndSelect() {
        const {findOneByKey, findOneByUrl, location} = this.props
        const {selected} = this.state
        const mainMenu = findOneByKey(MAIN_MENU);
        let route = findOneByUrl(location.pathname);
        const item = !route ? mainMenu.items.find(i => i.url === location.pathname) : undefined
        const selectedItem = mainMenu.items.find(i => i.uuid === selected?.[0])
        const selectedItemUrl = selectedItem?.url || selectedItem?.route?.url

        const selectedPrevious = selectedItemUrl && location.pathname.includes(selectedItemUrl) ? selected : []
        if (route || item) {
            let selectedParentKeys = [] as string[]
            route?.items?.forEach(item => {
                this.getActiveItemParents(item, mainMenu, selectedParentKeys);
            })
            this.setState(state => ({
                opened: _.union(state.opened, this.getLastOpenedGroups(), selectedParentKeys.filter(p => !state.opened.includes(p))),
                selected: item ? [item.uuid] : (route?.items?.length ? route?.items : selectedPrevious)
            }), () => this.setLastOpenedGroups(this.state.opened))
        } else {
            this.setState({selected: selectedPrevious})
        }
    }

    getActiveItemParents(current: string, mainMenu: IMenu, selectedKeys: string[]) {
        const parent = mainMenu.items.find(i => i.uuid === current)?.parent
        if (parent) {
            selectedKeys.push(parent)
            this.getActiveItemParents(parent, mainMenu, selectedKeys)
        }
    }

    render() {
        const {loading, bookmark, opened, selected} = this.state
        const {
            findOneByKey,
            _history,
            _match,
            isMobile,
            bookmarks,
            navCollapsed,
            settings,
            toggleCollapsedNav
        } = this.props
        const mainMenu = findOneByKey(MAIN_MENU);

        return (
            <Row className={'flex-column h-100'} wrap={false}>
                {bookmark && <BookmarkModal bookmark={bookmark} onFinish={() => this.setState({bookmark: undefined})}/>}
                {!isMobile &&
                    <Row align={"middle"} justify={"space-between"} wrap={false} className={'position-relative'}>
                        <Link to={{pathname: "/app"}}>
                            {(navCollapsed || !isMobile) && (
                                <Logo square={(navCollapsed && !isMobile) || (!navCollapsed && isMobile)}
                                      isMobile={isMobile}
                                      settings={settings}/>
                            )}
                        </Link>
                        <li className={"ant-menu-item ant-menu-item-only-child p-1 m-2" + (navCollapsed && !isMobile ? " menu-open-nav shadow bg-white mx-0" : '')}
                            onClick={() => toggleCollapsedNav?.(!navCollapsed)}>
                            {navCollapsed ? <MenuUnfoldOutlined className="nav-icon"/> :
                                <MenuFoldOutlined className="nav-icon"/>}
                        </li>
                    </Row>
                }
                <ScrollContainer shadowCoverColor={SettingsService.getValue('ui-theme', 'sideMenu')}>
                    <Spin wrapperClassName={'h-100'} spinning={loading}>
                        <Menu
                            theme="light"
                            mode="inline"
                            inlineCollapsed={navCollapsed}
                            onOpenChange={keys => this.setOpened(keys as string[])}
                            style={{height: "100%", borderRight: 0, background: "transparent"}}
                            selectedKeys={selected}
                            openKeys={opened}
                            // className={hideGroupTitle ? "hide-group-title" : ""}
                        >

                            {/* need to render bookmarks here - https://github.com/ant-design/ant-design/issues/10688 */}
                            {isMobile && bookmarks.length > 0 && (
                                <Menu.ItemGroup title={(<>
                                    Oblíbené
                                    {isMobile && (
                                        <span className={'float-right'}>
                                        <Bookmark history={_history} match={_match}/>
                                    </span>
                                    )}
                                </>)} key={'bookmarks'}>
                                    {bookmarks.map(bookmark => (
                                        <Menu.Item key={bookmark.id} onClick={() => this.onClickBookmark()}
                                                   style={{position: 'relative'}}>
                                            <Link to={bookmark.url}>
                                                {bookmark.icon && IconBuilder(bookmark.icon)}
                                                <span>{bookmark.title}</span>
                                            </Link>
                                            <Dropdown trigger={['click']} overlay={(
                                                <Menu>
                                                    <Menu.Item key="5" icon={<ExportOutlined/>}
                                                               onClick={() => window.open(bookmark.url, "_blank")}>Otevřít
                                                        na nové kartě</Menu.Item>
                                                    <Menu.Item key="1" icon={<EditOutlined/>}
                                                               onClick={() => this.editBookmark(bookmark)}>Upravit</Menu.Item>
                                                    <Menu.Item key="2" icon={<UpOutlined/>}
                                                               onClick={() => this.moveBookmark(bookmark, -1)}>Posunout
                                                        nahoru</Menu.Item>
                                                    <Menu.Item key="3" icon={<DownOutlined/>}
                                                               onClick={() => this.moveBookmark(bookmark, 1)}>Posunout
                                                        dolu</Menu.Item>
                                                    <Menu.Item key="4" icon={<DeleteOutlined/>}
                                                               onClick={() => this.removeBookmark(bookmark)}>Smazat</Menu.Item>
                                                </Menu>
                                            )}>
                                                <span
                                                    // onClick={(e) => this.editBookmark(bookmark, e)}
                                                    className={"float-right font-size-md"}
                                                    style={{
                                                        width: '1em',
                                                        height: '1em',
                                                        lineHeight: '1em',
                                                        zIndex: 999,
                                                        top: '50%',
                                                        transform: 'translateY(-50%)',
                                                        position: 'relative',
                                                        marginRight: 8
                                                    }}
                                                >
                                                    <EllipsisVertical style={{height: '.8em', width: '.8em'}}/>
                                                </span>
                                            </Dropdown>
                                        </Menu.Item>
                                    ))}
                                </Menu.ItemGroup>
                            )}

                            {mainMenu && mainMenu.items.length > 0 && (
                                <Menu.ItemGroup
                                    key={mainMenu.key}
                                    title={mainMenu.title}
                                >
                                    {MenuGroupBuilder.buildChildren(mainMenu.items, null, this.onClick, _history)}
                                </Menu.ItemGroup>
                            )}
                        </Menu>
                    </Spin>
                </ScrollContainer>
            </Row>
        );
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        bookmarks: state.setup.bookmarks,
        settings: state.setup.settings,
        findOneByKey: (key: string) => selectors.menu.findOneByKey(state, key),
        findOneByUrl: (path: string) => selectors.routes.findOneByUrl(state, path)
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        bookmarksUpdate: (bookmarks: IBookmark[]) => dispatch(bookmarksUpdate(bookmarks)),
        toggleCollapsedNav: (toggle: boolean) => dispatch(toggleCollapsedNav(toggle)),
        onMobileNavToggle: (toggle: boolean) => dispatch(onMobileNavToggle(toggle))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MenuContent))