import React, {RefObject} from "react";
import {Col, Form, FormInstance, Input, message, Modal, Row, Tooltip} from "antd";
import IPlan, {PlanState} from "../../../../../model/interface/company/workload/IPlan";
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    LockOutlined,
    SendOutlined
} from "@ant-design/icons";
import PlansService from "../../../../../model/service/company/workload/PlansService";
import RequestFormModal from "./RequestFormModal";
import ILabelValue from "../../../../../model/interface/util/ILabelValue";
import IType from "../../../../../model/interface/company/workload/IType";
import Button from "../../../../shared/button/Button";

interface IProps {
    plan: IPlan
    onFinish?: () => void,
    employeeChoices?: ILabelValue[],
    types?: IType[]
    canEdit?: boolean
    canDelete?: boolean
    canReject?: boolean
}

interface IState {
    changingState?: PlanState
    editing?: boolean,
    edit?: IPlan,
    deleting?: boolean
}

class PlanActions extends React.Component<IProps, IState> {

    formStateChangeRef: RefObject<FormInstance> = React.createRef()

    constructor(props: IProps) {
        super(props)
        this.state = {}
    }

    changeState = (plan: IPlan, state: PlanState) => {
        const {onFinish} = this.props
        Modal.confirm({
            title: {
                approved: "Opravdu schválit?",
                rejected: "Opravdu zamítnout?",
                planned: "",
                spent: "Opravdu nastavit jako vyčerpáno?",
                new: 'Opravdu odeslat ke schválení?'
            }[state],
            okText: "Ano",
            cancelText: "Ne",
            content: <Form ref={this.formStateChangeRef} hidden={state !== 'rejected'}>
                <Form.Item name={'stateChangeNote'} noStyle={true}>
                    <Input.TextArea placeholder={'Důvod'}/>
                </Form.Item>
            </Form>,
            onOk: () => {
                this.setState({changingState: state})
                this.formStateChangeRef.current?.validateFields().then(values => {
                    PlansService.resourceUpdateState(plan, state, values).then(() => {
                        message.success('Úspěšně upraveno').then()
                        onFinish?.()
                    }).finally(() => this.setState({changingState: undefined}))
                })
            }
        })
    }

    edit = (resource?: IPlan) => {
        this.setState({edit: resource, editing: !!resource})
    }

    delete = (resource: IPlan) => {
        const {onFinish} = this.props
        Modal.confirm({
            title: "Opravdu smazat?",
            icon: <DeleteOutlined/>,
            onOk: () => {
                this.setState({deleting: true})
                PlansService.resourceDelete(resource.uuid).then(() => {
                    message.success('Úspěšně smazano').then()
                })
                    .finally(() => {
                        this.setState({deleting: false})
                        onFinish?.()
                    })
            }
        })
    }

    onFormFinish = () => {
        const {onFinish} = this.props
        this.edit()
        onFinish?.()
    }

    render() {
        const {plan, employeeChoices, types, canEdit, canDelete, canReject} = this.props
        const {changingState, editing, edit, deleting} = this.state
        return (
            <>
                {edit && <RequestFormModal resource={plan} choices={employeeChoices} types={types} onCancel={this.edit}
                                           onSuccess={this.onFormFinish} employee={plan.employee?.uuid}/>}
                <Row justify={"end"} className={'flex-nowrap'} gutter={[6, 6]}>
                    {plan._permissions!.approve && (
                        <Col>
                            <Tooltip title={"Schválit [" + plan.type?.title + "]"}>
                                <Button loading={changingState === 'approved'} type="success" icon={<CheckOutlined/>}
                                        size="small" onClick={() => this.changeState(plan, "approved")}/>
                            </Tooltip>
                        </Col>
                    )}
                    {plan._permissions!.spent && (
                        <Col>
                            <Tooltip title={"Nastavit jako vyčerpané [" + plan.type?.title + "]"}>
                                <Button loading={changingState === 'spent'} icon={<LockOutlined/>} size="small"
                                        onClick={() => this.changeState(plan, "spent")}/>
                            </Tooltip>
                        </Col>
                    )}
                    {plan._permissions!.edit && canEdit !== false && (
                        <Col>
                            <Tooltip title="Upravit">
                                <Button onClick={() => this.edit(plan)} type="default"
                                        icon={<EditOutlined/>} size="small" loading={editing}/>
                            </Tooltip>
                        </Col>
                    )}
                    {plan._permissions!.ask && (
                        <Col>
                            <Tooltip title={"Odeslat ke schválení [" + plan.type?.title + "]"}>
                                <Button loading={changingState === 'new'} icon={<SendOutlined/>} size="small"
                                        type={'info'} onClick={() => this.changeState(plan, "new")}/>
                            </Tooltip>
                        </Col>
                    )}
                    {plan._permissions!.reject && canReject !== false && (
                        <Col>
                            <Tooltip title={"Zamítnout žádost [" + plan.type?.title + "]"}>
                                <Button loading={changingState === 'rejected'} icon={<CloseOutlined/>} size="small"
                                        onClick={() => this.changeState(plan, "rejected")} type={'danger'}/>
                            </Tooltip>
                        </Col>
                    )}
                    {plan._permissions!.delete && canDelete !== false && (
                        <Col>
                            <Tooltip title={"Smazat žádost [" + plan.type?.title + "]"}>
                                <Button loading={deleting} icon={<DeleteOutlined/>} size="small" danger
                                        onClick={() => this.delete(plan)}/>
                            </Tooltip>
                        </Col>
                    )}
                </Row>
            </>
        )
    }
}

export default PlanActions