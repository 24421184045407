import React from "react"
import {Calendar as AntdCalendar, Tooltip} from "antd";
import moment, {Moment} from "moment";
import {ICalendarSharedProps, IEvent} from "./Calendar";
import Cell from "./Cell";
import {CalendarMode} from "antd/es/calendar/generateCalendar";
import {MomentBuilder} from "../../../utils/MomentBuilder";


interface IState {
    selectStart?: Moment
    selectEnd?: Moment
    newEvent?: IEvent
}

interface IProps extends ICalendarSharedProps{
    onChange?: (date: Moment) => void
    mode: CalendarMode
}

class MonthYear extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    selectByDrag = (date: Moment, done: boolean = false) => {
        const unit = this.getCurrentModeUnit()
        this.props.onSelect && this.setState(state => {
            if (state.selectStart && state.newEvent) {
                const [start, end] = [moment.min(state.selectStart, date), moment.max(state.selectStart, date)]
                if (done) {
                    this.onSelect(start.startOf(unit), end.endOf(unit))
                }
                return {
                    selectEnd: date,
                    newEvent: {
                        ...state.newEvent,
                        startAt: start.startOf(unit),
                        endAt: end.endOf(unit)
                    }
                }
            }
            document.onkeydown = (evt) => {
                if ("key" in evt && (evt.key === "Escape" || evt.key === "Esc")) {
                    this.unsetDragSelect()
                    document.onkeydown = null
                }
            }
            return {
                selectStart: date,
                newEvent: {
                    startAt: date.startOf(unit), color: '#17bcff', label: '(bez názvu)', key: '',
                    endAt: date.clone().endOf(unit)
                }
            }
        })
    }

    unsetDragSelect = () => {
        this.setState({selectStart: undefined, selectEnd: undefined, newEvent: undefined})
    }

    dateFullCellRender = (date: Moment) => {
        const {newEvent} = this.state
        const {freeDays, highlightHolidays} = this.props
        let className = 'ant-picker-cell-inner ant-picker-calendar-date h-100 prevent-select'
        className += date.isSame(moment(), 'd') ? ' ant-picker-calendar-date-today' : ''
        const freeDay = highlightHolidays && freeDays?.find(f => MomentBuilder.build(f.date).isSame(date, 'date'))
        if (freeDay){
            className += ' bg-gray-lightest'
        }

        return <Tooltip title={freeDay && freeDay.type?.description}>
            <div className={className} onMouseDown={() => this.selectByDrag(date)}
                    onMouseUp={newEvent ? () => this.selectByDrag(date, true) : undefined}
                    onMouseOver={newEvent ? () => this.selectByDrag(date) : undefined}
                    style={{cursor: newEvent ? 'move' : 'pointer'}}>
            <div className="ant-picker-calendar-date-value">{date.format('DD')}</div>
            <div className="ant-picker-calendar-date-content">
                {this.dateCellRender(date)}
            </div>
        </div>
        </Tooltip>
    }

    monthFullCellRender = (date: Moment) => {
        const {newEvent} = this.state
        let className = 'ant-picker-cell-inner ant-picker-calendar-date h-100 prevent-select'
        className += date.isSame(moment(), 'd') ? ' ant-picker-calendar-date-today' : ''

        return <div className={className} onMouseDown={() => this.selectByDrag(date)}
                    onMouseUp={newEvent ? () => this.selectByDrag(date, true) : undefined}
                    onMouseOver={newEvent ? () => this.selectByDrag(date) : undefined}
                    style={{cursor: newEvent ? 'move' : 'pointer'}}>
            <div className="ant-picker-calendar-date-value">{date.format('MMM')}</div>
            <div className="ant-picker-calendar-date-content">
                {this.dateCellRender(date)}
            </div>
        </div>
    }

    dateCellRender = (date: Moment) => {
        const {newEvent} = this.state
        const {eventRender, events} = this.props;
        const allEvents = newEvent ? [...events || [], newEvent] : events || []
        return <Cell date={date} onMouseDown={e => e.stopPropagation()}
                     className={newEvent && date.isBetween(newEvent?.startAt, newEvent?.endAt, 'd', '[]') ? 'border' : ''}
                     events={allEvents.sort((a, b) => a.startAt.valueOf() - (b.endAt?.valueOf() || 0))
                         .filter(e => date.isBetween(moment(e.startAt), moment(e.endAt), 'day', '[]'))}
                     eventRender={eventRender}></Cell>
    }

    onSelect = (start: Moment, end: Moment) => {
        this.props.onSelect?.(start, end)
    }

    getCurrentModeUnit() {
        const {mode} = this.props
        return mode === 'month' ? 'day' : 'month';
    }

    render() {
        const {date, mode, onChange, highlightWeekend} = this.props;
        return (
            <div onMouseLeave={this.unsetDragSelect}>
                <AntdCalendar
                    value={date}
                    className={highlightWeekend ? 'highlight-weekend' : ''}
                    mode={mode}
                    headerRender={() => <></>}
                    dateFullCellRender={date => this.dateFullCellRender(date)}
                    monthFullCellRender={date => this.monthFullCellRender(date)}
                    onChange={onChange}
                />
            </div>
        )
    }
}

export default MonthYear