import {LabeledValue} from "antd/es/select";
import IShift, {
    COMPANY_SHIFT_TYPE_DAILY,
    COMPANY_SHIFT_TYPE_EACH_WORK_DAY,
    COMPANY_SHIFT_TYPE_MONTHLY,
    COMPANY_SHIFT_TYPE_ON_HOLIDAY,
    COMPANY_SHIFT_TYPE_WEEKLY,
    COMPANY_SHIFT_TYPE_YEARLY
} from "../../../interface/company/IShift";
import moment, {Moment} from "moment";
import IFreeDay from "../../../interface/company/workload/IFreeDay";
import RestService from "../../dataStorage/RestService";
import IRestServiceOptions from "../../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../../interface/api/IRestServiceCollectionResponse";

interface IRestShiftsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IShift>
}

interface IRestShiftsService {
    collectionList(options?: IRestServiceOptions): Promise<IRestShiftsServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IShift>

    resourceRetrieve(id: number | string): Promise<IShift>

    resourceUpdate(id: number | string, data: any): Promise<IShift>

    resourceDelete(id: number | string): Promise<void>
    
    getShiftRepeatOptions(): LabeledValue[],

    isShiftActive(shift: IShift, date: Moment, freeDays?: IFreeDay[]): boolean

    getStartAtDate: (shift: IShift, date?: Moment) => Moment

    getEndAtDate: (shift: IShift, date?: Moment) => Moment

    getCurrentShift: (shifts: IShift[], time: Moment) => undefined | IShift
}

const COLLECTION = 'company/shifts'


const ShiftsService: IRestShiftsService = {
    collectionList: function (options?) {
        return RestService.collectionList(COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestShiftsServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(COLLECTION, data) as Promise<IShift>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(COLLECTION, id) as Promise<IShift>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(COLLECTION, id, data) as Promise<IShift>
    },
    getShiftRepeatOptions() {
        return [
            {
                label: 'Pracovní den',
                value: COMPANY_SHIFT_TYPE_EACH_WORK_DAY
            },
            {
                label: 'Každý den',
                value: COMPANY_SHIFT_TYPE_DAILY
            },
            {
                label: 'Týdně',
                value: COMPANY_SHIFT_TYPE_WEEKLY
            },
            {
                label: 'Měsíčně',
                value: COMPANY_SHIFT_TYPE_MONTHLY
            },
            {
                label: 'Ročně',
                value: COMPANY_SHIFT_TYPE_YEARLY
            },
            {
                label: 'Ve svátek',
                value: COMPANY_SHIFT_TYPE_ON_HOLIDAY
            }
        ];
    },
    isShiftActive(shift: IShift, date: Moment, freeDays: IFreeDay[] = []): boolean {
        switch(shift.type) {
            case('monthly'):
                return date.date() === shift.value
            case('daily'):
                return true
            case('on_holiday'):
                return !!freeDays.find(f => moment(f.date).isSame(date))
            case('weekly'):
                return date.isoWeekday() === shift.value
            case('yearly'):
                return date.dayOfYear() === shift.value
            case('work_day'):
                return [6,7].indexOf(date.isoWeekday()) < 0 && !freeDays.find(f => moment(f.date).isSame(date))
        }
    },
    getStartAtDate: (shift: IShift, date?: Moment) =>
        (date || moment()).clone().hour(shift.startAtTimeHours).minute(shift.startAtTimeMinutes),
    getEndAtDate: (shift: IShift, date?: Moment) =>
        (date || moment()).clone().hour(shift.endAtTimeHours).minute(shift.endAtTimeMinutes),
    getCurrentShift: (shifts: IShift[], time: Moment) => {
        const sorted = shifts.sort((a, b) => (a.endAtTimeHours + (a.endAtTimeMinutes / 60) - (b.endAtTimeHours + (b.endAtTimeMinutes / 60))))
        return sorted.find(s => ShiftsService.getStartAtDate(s, time).isBefore(time)) || sorted[0]
    }
}

export default ShiftsService