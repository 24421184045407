import RestService from "model/service/dataStorage/RestService";
import IRestServiceCollectionResponse from "../../../interface/api/IRestServiceCollectionResponse";
import IRestServiceOptions from "../../../interface/api/IRestServiceOptions";
import IObligation from "../../../interface/company/IObligation";
import IRestServiceChoiceListResponse from "../../../interface/api/IRestServiceChoiceListResponse";
import {Moment} from "moment";
import ShiftsService from "./ShiftsService";
import IFreeDay from "../../../interface/company/workload/IFreeDay";

interface IRestObligationsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IObligation>
}

interface IRestObligationsService {

    collectionList(options?: IRestServiceOptions): Promise<IRestObligationsServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IObligation>,

    resourceRetrieve(id: number | string): Promise<IObligation>,

    resourceUpdate(id: number | string, data: any): Promise<IObligation>,

    resourceDelete(id: number | string): Promise<void>,

    choiceList(presenterName?: string, options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse>

    getTotalShiftHoursByDate(obligation: IObligation, date: Moment, freeDays?: IFreeDay[]): number
}

const ObligationsService_COLLECTION = 'company/obligations'
const CHOICES = 'company/obligations-choices/'

const ObligationsService: IRestObligationsService = {
    collectionList: function (options?) {
        return RestService.collectionList(ObligationsService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestObligationsServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(ObligationsService_COLLECTION, data) as Promise<IObligation>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(ObligationsService_COLLECTION, id) as Promise<IObligation>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(ObligationsService_COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(ObligationsService_COLLECTION, id, data) as Promise<IObligation>
    },
    choiceList(presenterName: string = 'default', options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse> {
        return RestService.collectionList(CHOICES + presenterName, options) as unknown as Promise<IRestServiceChoiceListResponse>
    },
    getTotalShiftHoursByDate(obligation, date, freeDays): number {
        return obligation?.shifts.reduce((total, shift) => total + (ShiftsService.isShiftActive(shift, date, freeDays) ? shift.totalHours : 0), 0)
    }
}

export default ObligationsService