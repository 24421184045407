import {Card, FormInstance} from 'antd';
import React, {RefObject} from "react";
import IContentType from "../../../../../../../model/interface/dataStorage/IContentType";
import IBaseProps from "../../../../../../../model/interface/IBaseProps";
import {ICompanyStructureEditorOptions} from "./FormFieldCompanyStructureEditor";
import IField from "../../../../../../../model/interface/dataStorage/IField";
import FieldEmployeeOptionsEditor from "../../../../content-type/field/optionEditors/FieldEmployeeOptionsEditor";

export interface IEmployeeEditorOptions {
    employeeOnlyDirectChildren?: boolean
    employeeStructureRoot?: string | string[]
    employeeMultiple?: boolean,
    employeeSelectFirst?: boolean
}

interface IProps extends IBaseProps {
    options: ICompanyStructureEditorOptions
    formRef: RefObject<FormInstance>
    contentType: IContentType
    field: IField
}

interface IState {

}

class FormFieldEmployeeEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    render() {
        const {options, history, match, field} = this.props
        return (
            <Card>
               <FieldEmployeeOptionsEditor buildFieldName={(...args) => args} field={field} options={options} match={match} history={history}/>
            </Card>
        )
    }
}

export default FormFieldEmployeeEditor