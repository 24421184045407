import React from "react";
import {Button, Modal as AntdModal, Tabs} from "antd";
import FormProperties from "./FormProperties";
import FormEditor from "./FormEditor";
import IForm from "../../../../model/interface/form/IForm";
import IBaseProps from "../../../../model/interface/IBaseProps";
import {BarsOutlined, FormOutlined, OrderedListOutlined} from "@ant-design/icons";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import FormEvaluation from "./FormEvaluation";
import Modal from "../../../shared/modal/Modal";

export type FormConfigurationStep = 'properties' | 'evaluation' | 'editor' | string

interface IState {
    saving: boolean
    showAll: boolean
    form: IForm
    step: FormConfigurationStep,
    changedForms: IForm[],
    valid: boolean
}

interface IProps extends IBaseProps {
    update?: (form: IForm, forms?: IForm[]) => void
    cancel: () => void
    form: IForm
    contentType: IContentType
    step?: FormConfigurationStep
}

export interface IFormStepProps extends IBaseProps {
    onChange: (form: IForm, changedForms?: IForm[], confirm?: boolean, stepBack?: boolean) => void
    form: IForm,
    contentType: IContentType
}

class FormModal extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            saving: false,
            valid: true,
            showAll: false,
            form: {...props.form},
            step: props.step || 'properties',
            changedForms: []
        }
    }

    onChange = (form: IForm, changedForms?: IForm[], confirm: boolean = false, stepBack: boolean = false) => {
        this.setState(state => ({
            form: {
                ...state.form,
                ...form,
            },
            changedForms: changedForms || state.changedForms
        }), () => confirm && this.setStep('', stepBack))
    }

    setStep = (step: FormConfigurationStep, stepBack: boolean = false) => {
        const currentIndex = this.getSteps().findIndex(s => s.key === (step || this.state.step))
        this.setState({step: step ? step : this.getSteps()[currentIndex + (stepBack ? -1 : 1)]?.key})
    }

    onCancel = () => {
        this.state.form && JSON.stringify(this.props.form) !== JSON.stringify(this.state.form) ? AntdModal.confirm({
            title: 'Pozor, pokud budete pokračovat, všechny změny budou ztraceny!',
            onOk: () => {
                AntdModal.destroyAll();
                this.props.cancel()
            },
            onCancel() {
            },
            type: 'warning'
        }) : this.props.cancel()
    }

    update = () => {
        const {form, changedForms} = this.state
        if (form && form.name && form.label) {
            this.setState({saving: true})
            this.updateParent(form, changedForms);
        }
    }

    updateParent(form: IForm, changedForms?: IForm[]) {
        this.props.update && this.props.update(form, changedForms)
    }

    setValid = (valid: boolean) => {
        this.setState({valid})
    }

    render() {
        const {saving, form, step, valid} = this.state
        const tabs = this.getSteps()

        return (
            <Modal
                fullScreenOption={true}
                title={form.id ? `Uprava formuláře [${form.name}]` : 'Nový formulář'}
                visible={true}
                onCancel={this.onCancel}
                width={'80vw'}
                destroyOnClose={true}
                bodyStyle={{height: '65vh', overflow: "auto"}}
                footer={[
                    <Button key="back" onClick={this.onCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" loading={saving} disabled={Number(step) < tabs.length || !valid}
                            onClick={() => this.update()}>
                        Submit
                    </Button>
                ]}
            >
                <Tabs className={'h-100 content-h-100'} activeKey={step} onChange={this.setStep}>
                    {tabs.map((tab, index) => (
                        <Tabs.TabPane className={'h-100'} tab={<div>{tab.icon} {tab.label}</div>}
                                      key={tab.key} disabled={index < tabs.length}>
                            {tab.widget}
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </Modal>
        )
    }

    getSteps() {
        const {form} = this.state
        const {history, match, contentType} = this.props

        const stepProps = {form, onChange: this.onChange, history, match, contentType}

        return [
            {
                label: 'Vlastnosti',
                key: 'properties',
                icon: <BarsOutlined/>,
                widget: <FormProperties {...stepProps} />
            },
            {
                label: 'Vyhodnocení',
                key: 'evaluation',
                icon: <OrderedListOutlined/>,
                widget: <FormEvaluation {...stepProps} />
            },
            {
                label: 'Editor',
                key: 'editor',
                icon: <FormOutlined/>,
                widget: <FormEditor {...stepProps} setValid={this.setValid}/>
            }
        ];
    }
}

export default FormModal